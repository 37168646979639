.no-border-bottom {
  border-bottom: none !important;
}

.no-border {
  border: none !important;
}

.border-end-white {
  border-right: 1px solid $white;
}

.border-light-blue {
  border: 1px solid #5590f5;
}

.insights-pie-chart *:focus {
  outline: none;
}
