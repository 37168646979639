.quick-request {
  &-nav-item {
    font-size: 0.8rem;
    font-weight: bold;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: black;
  }
}

.nav-item-underline {
  background: $primary;
  width: 70%;
  height: 7px;
}
