.qwoted-btn-xs {
  padding: 2px 8px;
}

/* stylelint-disable-next-line -- We don't control these class names so can't make them kebab case  */
.products, .sources, .companies, .pr_users, .public_pitches, .press_releases {
  .ais-SearchBox { /* stylelint-disable-line -- We don't control these class names so can't make them kebab case */
    width: 100%;

    @media (min-width: 892px) {
      width: 70%;
    }

    form.ais-SearchBox-form { /* stylelint-disable-line -- We don't control these class names so can't make them kebab case */
      width: 100%;

      .ais-SearchBox-reset { /* stylelint-disable-line -- We don't control these class names so can't make them kebab case */
        display: flex;
      }
    }
  }

  #menu-bar {
    #nav-container {
      @media screen and (max-width: 576px) {
        width: 100%;
      }
    }

    #search-form-container {
      @media screen and (max-width: 576px) {
        width: 100%;
      }

      .ais-SearchBox-input { /* stylelint-disable-line -- We don't control these class names so can't make them kebab case */
        @media screen and (max-width: 576px) {
          width: 100%;
        }
      }
    }
  }

  .dropdown-toggle::after {
    @media screen and (max-width: 576px) {
      float: right;
      display: inline-block;
      margin-top: 0.5rem;
    }
  }
}

.products {
  .product-categories-filter-panel {
    .ais-RefinementList { /* stylelint-disable-line -- We don't control these class names so can't make them kebab case */
      .ais-RefinementList-labelText { /* stylelint-disable-line -- We don't control these class names so can't make them kebab case */
        margin-left: 0.5em;
      }

      .ais-RefinementList-count { /* stylelint-disable-line -- We don't control these class names so can't make them kebab case */
        display: none; /* Hide the count as per design and due to confusing UI */
      }
    }
  }

  .product-samples-available-panel {
    .ais-ToggleRefinement-label { /* stylelint-disable-line -- We don't control these class names so can't make them kebab case */
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;

      .ais-ToggleRefinement-labelText { /* stylelint-disable-line -- We don't control these class names so can't make them kebab case */
        margin-left: 0.5em;
      }
    }
  }
}
