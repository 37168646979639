.md-editor {
  background: white;
  border: 1px solid rgb(0 0 0 / 15%);

  .md-editor-textarea {
    min-height: 200px;
    height: auto;
    border: 0;
    border-top: 1px solid rgb(0 0 0 / 15%);
  }

  .md-editor-header {
    background: $gray-100;
    padding-top: 10px;
    padding-right: 10px;
    padding-left: 10px;

    .tabnav-button {
      display: inline-block;
      padding: 8px 12px;
      font-size: 14px;
      line-height: 20px;
      color: $gray-600;
      text-decoration: none;
      background-color: transparent;
      border: 1px solid transparent;
      border-bottom: 0;
    }

    .md-editor-toolbar {
      & > * {
        display: inline-block;
      }

      & > * + * {
        margin-left: 15px;
      }

      float: right;
    }

    .md-editor-icon {
      font-size: 15px;
      vertical-align: text-bottom;
      color: gray;
      cursor: pointer;

      &:hover {
        color: black;
      }
    }

    .tabnav-tabs {
      display: inline-block;
      margin-bottom: -2px;

      .tabnav-tab {
        color: gray;
        outline: none;
        cursor: pointer;
        text-decoration: none;
        padding: 7px 20px;

        &:hover {
          color: black;
        }

        &.selected {
          background: white;
          border-top: 1px solid rgb(0 0 0 / 15%);
          border-left: 1px solid rgb(0 0 0 / 15%);
          border-right: 1px solid rgb(0 0 0 / 15%);
          color: black;
        }
      }
    }
  }

  .md-editor-preview {
    min-height: 200px;
    padding: 5px 12px;
    border-top: 1px solid rgb(0 0 0 / 15%);
  }

  .md-editor-fileupload-container {
    position: relative;
    border-top: 1px dashed lightgray;
    padding-left: 12px;
    height: 25px;

    &:hover {
      border-top: 1px dashed gray;
    }

    label {
      cursor: pointer;
    }

    ::-webkit-file-upload-button {
      cursor: pointer;
    }

    .md-editor-fileupload {
      cursor: pointer;
      position: absolute;
      width: 100%;
      opacity: 0;
      top: 0;
      left: 0;
    }
  }
}
