.table-px-0 {
  tr,
  td {
    padding-left: 0;
    padding-right: 0;
  }
}

.table-borderless {
  tbody tr td,
  tbody tr th,
  thead tr th {
    border: none;
  }
}

// TODO: Remove hard-coded 767px. It's weird that media-breakpoint-down(md) set breakpoint to 1070.348px
@media screen and (max-width: 767px) {
  /* Force table to not be like tables anymore */
  table.custom-table-md,
  .custom-table-md thead,
  .custom-table-md tbody,
  .custom-table-md th,
  .custom-table-md td,
  .custom-table-md tr {
    display: block;
  }

  .custom-table-md tr {
    border: 1px solid $gray-400;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  .custom-table-md thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .custom-table-md td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid $gray-200;
    position: relative;
    padding-left: 50%;
  }

  .custom-table-md td::before {
    /* Now like a table header */
    position: absolute;
    width: 45%;
    left: 0.75rem;
    padding-right: 0.75rem;
    white-space: nowrap;

    /* Label the data */
    content: attr(title);
  }
}
