/* These styles isolatation is BEM-inspired */

.public-pitch-details-link-marker {
  display: inline-block;
  transition: transform 0.3s ease;
}

.public-pitch-details-link-toggled .public-pitch-details-link-marker {
  transform: rotate(90deg);
}

.public-pitch-details-link:hover .public-pitch-details-link-marker {
  transform: rotate(45deg);
}

.public-pitch-contact-link-icon {
  transition: transform 0.3s ease;
  display: inline-block;
  vertical-align: middle;
  padding-bottom: 3px;
}

@keyframes public-pitch-contact-link-icon-jump {
  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-5px);
  }
}

.public-pitch-contact-link:hover .public-pitch-contact-link-icon {
  animation: public-pitch-contact-link-icon-jump 0.3s ease;
}

.public-pitch-author-footer {
  height: 33px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}

.public-pitch-admin-text {
  white-space: pre-wrap;
}

.public-pitch-copied-message {
  background: grey;
  border-radius: 5px;
  color: white;
  font-size: 10px;
  font-weight: normal;
  margin-left: -2.8em;
  margin-top: -0.1em;
  padding: 3px 5px;
  position: absolute;
  transform: scale(0);
  opacity: 0;
}

.public-pitch-copied-message-animation {
  animation: fly-in-and-fade 0.5s;
}

@keyframes fly-in-and-fade {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  20% {
    transform: scale(1);
    opacity: 1;
  }

  70% {
    transform: scale(1.25);
    opacity: 0.5;
  }

  100% {
    transform: scale(2);
    opacity: 0;
  }
}

.public-pitch-full-summary {
  animation: scale-slide-y 0.25s;
  transform-origin: top;
  transform: scaleY(1);
}

@keyframes scale-slide-y {
  0% {
    transform: scaleY(0);
  }

  100% {
    transform: scaleY(1);
  }
}
