.nav-pills {
  .nav-link {
    background: $white;
    color: $black;

    .fa-solid,
    .fa-regular,
    .fa-brands {
      color: $primary;
    }
  }

  .active {
    background-color: $primary;
    color: $white;

    .fa-solid,
    .fa-regular,
    .fa-brands {
      color: $white;
    }
  }
}
