// "Note that the .text-white class has no link styling."
// https://v4-alpha.getbootstrap.com/utilities/colors/
.link-white {
  color: $white !important;

  a {
    color: $white !important;
  }
}

.link-text-color {
  color: $gray-900 !important;

  a {
    color: $gray-900 !important;
  }
}

.disabled-link-text-color {
  color: $gray-500 !important;

  a {
    color: $gray-500 !important;
  }
}

.text-underline-hover {
  text-decoration: none;
}

.text-underline-hover:hover {
  text-decoration: underline;
}

.text-nowrap-sm-up {
  @include media-breakpoint-up(sm) {
    white-space: nowrap !important;
  }
}

.text-smallcaps {
  text-transform: uppercase;
  font-size: 0.875em;
  letter-spacing: 0.07em;
}

.text-smallcaps-no-transform {
  text-transform: none;
  font-size: 0.875em;
  letter-spacing: 0.07em;
}

.font-size-14px {
  font-size: 0.88em !important;
}

.font-size-15px {
  font-size: 0.94em !important;
}

.font-size-36px {
  font-size: 36px !important;
}

.font-size-22px {
  font-size: 1.375rem !important;
}

.font-size-20px {
  font-size: 1.25rem !important;
}

.font-size-18px {
  font-size: 1.125rem !important;
}

.font-size-16px {
  font-size: 1em !important;
}

.font-size-12px {
  font-size: 0.75em !important;
}

.font-size-10px {
  font-size: 0.66em !important;
}

.font-size-9px {
  font-size: 0.6em !important;
}

.font-size-8px {
  font-size: 0.53em !important;
}

.font-family-lato {
  font-family: lato, sans-serif;
}

.font-family-proxima-nova {
  font-family: proxima-nova, sans-serif;
}

.form-control::placeholder {
  color: $gray-500;
}
