.tipsheet {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #2e75b5;
  }
}

.tipsheet-header {
  background: white;
  margin-bottom: 0;
  border-bottom: $primary;
  min-height: 150px;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $primary;
    font-weight: bolder;
  }

  .tipsheet-header-education {
    position: absolute;
    bottom: -20px;
  }
}
