.custom-navbar.navbar {
  .dropdown-menu {
    font-size: 100%;
  }

  .dropdown-item {
    font-weight: bold;
  }

  // colored 'active' underline bar
  .nav-link.active:not(.media-database-nav-link)::after {
    background: $brand;
    bottom: -1rem; // may need to be adjusted
    content: "";
    display: block;
    height: 0.25rem;
    left: 0;
    position: absolute;
    width: 100%;
  }

  .nav-link.nav-link-mobile {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .nav-link.nav-link-mobile.active {
    border-color: $brand;
    border-left: 5px solid;
    color: $brand;
  }

  .nav-link.nav-link-mobile.active::after {
    content: none;
  }

  .dropdown-submenu {
    position: relative;
  }

  .dropdown-submenu a::after {
    transform: rotate(-90deg);
    position: absolute;
    right: 6px;
    top: 0.8em;
  }

  .dropdown-submenu .dropdown-menu {
    top: 0;
    left: 100%;
    margin-left: 0.1rem;
    margin-right: 0.1rem;
  }

  @include media-breakpoint-down(md) {
    // @include media-breakpoint-down(md) {

    // hide carat
    .nav-link::after {
      color: red;
    }

    .dropdown-menu {
      border: none;
      padding: 0;
      margin-top: -0.33rem;
    }

    .dropdown-item {
      padding-left: 1rem;
    }
  }

  @include media-breakpoint-up(lg) {
    // vertically centering
    .navbar-nav {
      position: relative;
    }

    .nav-link {
      font-size: 100%;
      padding-left: 0.75rem !important;
      padding-right: 0.75rem !important;

      // colored 'active' underline bar
      position: relative;
    }

    // hide carat
    .nav-link::after {
      border: none;
      margin: 0;
    }

    .dropdown-menu {
      box-shadow: 0.125rem 0.125rem 0.3125rem $gray-500;
    }
  }
}
