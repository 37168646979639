.auto-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
}

.auto-truncate-table-cell {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 0;
}
