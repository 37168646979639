.ai-detector-response {
  .percentage {
    font-size: 24px;
    font-weight: bold;
  }

  .text-muted {
    font-size: 12px;
  }

  .fa-thumbs-up,
  .fa-thumbs-down {
    margin-left: 5px;
  }
}

.ai-detector-button {
  background-color: transparent;
  border: none;
  color: #5443bd;
  transition: none;
}

.ai-detector-button:hover,
.ai-detector-button:focus,
.ai-detector-button:active {
  background-color: transparent;
  color: #5443bd;
  border: none;
  outline: none;
  box-shadow: none;
}
