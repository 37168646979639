.card-grid {
  .card-grid-image {
    border-radius: 50% !important;
    width: 23%;
  }

  .card-grid-subtitle {
    font-family: lato, sans-serif;
    font-size: 0.75rem;
    line-height: 0.8125rem;
  }
}

// # TODO note https://getbootstrap.com/docs/4.3/components/card/#card-columns
// periodically reconsider how we float cards, if working extensively with this rule,
// consider refactoring as Bootstrap may have added comparative native functionality
.flex-card {
  display: flex;
  flex-direction: column;

  // http://stackoverflow.com/a/31002364/862027
  .card-sticky-footer {
    align-self: flex-end;
    width: 100%;
  }
}
