/* stylelint-disable */

//Original colors from Algolia:
//$color1: #0073a8;
//$color2: #0096db;
//$color3: #333;
//$color4: #3a4570;
//$color5: #46AEDA;
//$color6: #495588;
//$color7: #878faf;
//$color8: #DDD;
//$color9: #a5abc4;
//$color10: #a5aed1;
//$color11: #aaa;
//$color12: #c4c8d8;
//$color13: #dfe2ee;
//$color14: #e3e5ec;

//@if $primary != $periwinkle-70 {
//  @error "If the $primary color is changed from $periwinkle-70, the color mapping below will need to be reconsidered!";
//}

// These have been swapped out to be made up of the scheme defined in views_shared colors.scss
$color1: $periwinkle-70;
$color2: $periwinkle-50;
$color3: $pepper;
$color4: $periwinkle-80;
$color5: $periwinkle-50;
$color6: $periwinkle-90;
$color7: $periwinkle-40;
$color8: $fog;
$color9: $periwinkle-20;
$color10: $periwinkle-20;
$color11: $cement;
$color12: $periwinkle-20;
$color13: $periwinkle-20;
$color14: $periwinkle-20;
$color15: $gold-40;
$color16: $white;

[class^=ais-] label {
  margin-bottom: .5rem
}

a[class^=ais-] {
  text-decoration: none
}

ul.ais-NumericMenu-list li.ais-NumericMenu-item label.ais-NumericMenu-label input.ais-NumericMenu-radio {
  margin-right: .25rem
}

.ais-hide-counts span.ais-RefinementList-count {
  display: none
}

.ais-HierarchicalMenu-count, .ais-Menu-count, .ais-RefinementList-count, .ais-ToggleRefinement-count {
  padding: .1rem .4rem
}

.ais-Panel-body {
  overflow: clip
}

.ais-CurrentRefinements-list, .ais-HierarchicalMenu-list, .ais-Hits-list, .ais-InfiniteHits-list, .ais-InfiniteResults-list, .ais-Menu-list, .ais-NumericMenu-list, .ais-Pagination-list, .ais-RatingMenu-list, .ais-RefinementList-list, .ais-Results-list, .ais-ToggleRefinement-list, air-RefinementList .ais-Breadcrumb-list {
  margin: 0;
  padding: 0;
  list-style: none
}

.ais-RefinementList-checkbox, .ais-ToggleRefinement-checkbox {
  margin-right: 5px
}

.ais-ClearRefinements-button, .ais-CurrentRefinements-delete, .ais-CurrentRefinements-reset, .ais-GeoSearch-redo, .ais-GeoSearch-reset, .ais-HierarchicalMenu-showMore, .ais-InfiniteHits-loadMore, .ais-InfiniteHits-loadPrevious, .ais-InfiniteResults-loadMore, .ais-Menu-showMore {
  padding: 0;
  overflow: visible;
  font: inherit;
  line-height: normal;
  color: inherit;
  background: 0 0;
  border: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}

.ais-RangeInput-submit {
  padding: 0;
  overflow: visible;
  font: inherit;
  line-height: normal;
  color: inherit;
  background: 0 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}

.ais-RefinementList-showMore, .ais-SearchBox-reset, .ais-SearchBox-submit {
  padding: 0;
  border: 0
}

.ais-RefinementList-showMore, .ais-SearchBox-reset, .ais-SearchBox-submit, .ais-VoiceSearch-button {
  overflow: visible;
  font: inherit;
  line-height: normal;
  color: inherit;
  background: 0 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}

.ais-ClearRefinements-button::-moz-focus-inner, .ais-CurrentRefinements-delete::-moz-focus-inner, .ais-CurrentRefinements-reset::-moz-focus-inner, .ais-GeoSearch-redo::-moz-focus-inner, .ais-GeoSearch-reset::-moz-focus-inner, .ais-HierarchicalMenu-showMore::-moz-focus-inner, .ais-InfiniteHits-loadMore::-moz-focus-inner, .ais-InfiniteHits-loadPrevious::-moz-focus-inner, .ais-InfiniteResults-loadMore::-moz-focus-inner, .ais-Menu-showMore::-moz-focus-inner, .ais-RangeInput-submit::-moz-focus-inner, .ais-RefinementList-showMore::-moz-focus-inner, .ais-SearchBox-reset::-moz-focus-inner, .ais-SearchBox-submit::-moz-focus-inner, .ais-VoiceSearch-button::-moz-focus-inner {
  padding: 0;
  border: 0
}

.ais-ClearRefinements-button[disabled], .ais-CurrentRefinements-delete[disabled], .ais-CurrentRefinements-reset[disabled], .ais-GeoSearch-redo[disabled], .ais-GeoSearch-reset[disabled], .ais-HierarchicalMenu-showMore[disabled], .ais-InfiniteHits-loadMore[disabled], .ais-InfiniteHits-loadPrevious[disabled], .ais-InfiniteResults-loadMore[disabled], .ais-Menu-showMore[disabled], .ais-RangeInput-submit[disabled], .ais-RefinementList-showMore[disabled], .ais-SearchBox-reset[disabled], .ais-SearchBox-submit[disabled], .ais-VoiceSearch-button[disabled] {
  cursor: default
}

.ais-ClearRefinements-button-custom {
  line-height: 1.75rem
}

.ais-Breadcrumb-item, .ais-Breadcrumb-list, .ais-Pagination-list, .ais-PoweredBy, .ais-RangeInput-form, .ais-RatingMenu-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.ais-GeoSearch, .ais-GeoSearch-map {
  height: 100%
}

.ais-HierarchicalMenu-list .ais-HierarchicalMenu-list {
  margin-left: 1em
}

.ais-PoweredBy-logo {
  display: block;
  height: 1.2em;
  width: auto
}

.ais-RatingMenu-starIcon {
  display: block;
  height: 20px
}

.ais-SearchBox-input::-ms-clear, .ais-SearchBox-input::-ms-reveal {
  display: none;
  width: 0;
  height: 0
}

.ais-SearchBox-input::-webkit-search-cancel-button, .ais-SearchBox-input::-webkit-search-decoration, .ais-SearchBox-input::-webkit-search-results-button, .ais-SearchBox-input::-webkit-search-results-decoration {
  display: none
}

.ais-RangeSlider .rheostat {
  overflow: visible;
  margin-top: 40px;
  margin-bottom: 40px
}

.ais-RangeSlider .rheostat-background {
  height: 6px;
  top: 0;
  width: 100%
}

.ais-RangeSlider .rheostat-handle {
  margin-left: -12px;
  top: -7px
}

.ais-RangeSlider .rheostat-background {
  position: relative;
  background-color: $color16;
  border: 1px solid $color11
}

.ais-RangeSlider .rheostat-progress {
  position: absolute;
  top: 1px;
  height: 4px;
  background-color: $color3
}

.rheostat-handle {
  position: relative;
  z-index: 1;
  width: 20px;
  height: 20px;
  background-color: $color16;
  border: 1px solid $color3;
  border-radius: 50%;
  cursor: -webkit-grab;
  cursor: grab
}

.rheostat-marker {
  margin-left: -1px;
  position: absolute;
  width: 1px;
  height: 5px;
  background-color: $color11
}

.rheostat-marker--large {
  height: 9px
}

.rheostat-tooltip, .rheostat-value {
  margin-left: 50%;
  position: absolute;
  text-align: center;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%)
}

.rheostat-value {
  padding-top: 15px
}

.rheostat-tooltip {
  top: -22px
}

[class^=ais-] {
  font-size: 1rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box
}

[class^=ais-Highlight], [class^=ais-Snippet] {
  font-size: inherit !important
}

.ais-Breadcrumb, .ais-ClearRefinements, .ais-CurrentRefinements, .ais-GeoSearch, .ais-HierarchicalMenu, .ais-Hits, .ais-HitsPerPage, .ais-InfiniteHits, .ais-InfiniteResults, .ais-Menu, .ais-MenuSelect, .ais-NumericMenu, .ais-NumericSelector, .ais-Pagination, .ais-Panel, .ais-PoweredBy, .ais-RangeInput, .ais-RangeSlider, .ais-RatingMenu, .ais-RefinementList, .ais-Results, .ais-ResultsPerPage, .ais-SearchBox, .ais-SortBy, .ais-Stats, .ais-ToggleRefinement {
  color: $color4
}

.ais-Breadcrumb-item--selected, .ais-HierarchicalMenu-item--selected, .ais-Menu-item--selected {
  font-weight: 700
}

.ais-Breadcrumb-separator {
  margin: 0 .3em;
  font-weight: 400
}

.ais-Breadcrumb-link, .ais-HierarchicalMenu-link, .ais-Menu-link {
  color: $color2;
  -webkit-transition: color .2s ease-out;
  transition: color .2s ease-out
}

.ais-Pagination-link {
  color: $color2
}

.ais-RatingMenu-link {
  color: $color2;
  -webkit-transition: color .2s ease-out;
  transition: color .2s ease-out
}

.ais-Breadcrumb-link:focus, .ais-Breadcrumb-link:hover, .ais-HierarchicalMenu-link:focus, .ais-HierarchicalMenu-link:hover, .ais-Menu-link:focus, .ais-Menu-link:hover, .ais-Pagination-link:focus, .ais-Pagination-link:hover, .ais-RatingMenu-link:focus, .ais-RatingMenu-link:hover {
  color: $color1
}

.ais-ClearRefinements-button, .ais-CurrentRefinements-reset, .ais-GeoSearch-redo, .ais-GeoSearch-reset, .ais-HierarchicalMenu-showMore, .ais-InfiniteHits-loadMore, .ais-InfiniteHits-loadPrevious, .ais-InfiniteResults-loadMore, .ais-Menu-showMore, .ais-RefinementList-showMore {
  padding: .3rem .5rem;
  font-size: .8rem;
  color: $color16;
  background-color: $color2;
  border-radius: 5px;
  -webkit-transition: background-color .2s ease-out;
  transition: background-color .2s ease-out;
  outline: 0
}

.ais-ClearRefinements-button:focus, .ais-ClearRefinements-button:hover, .ais-CurrentRefinements-reset:focus, .ais-CurrentRefinements-reset:hover, .ais-GeoSearch-redo:focus, .ais-GeoSearch-redo:hover, .ais-GeoSearch-reset:focus, .ais-GeoSearch-reset:hover, .ais-HierarchicalMenu-showMore:focus, .ais-HierarchicalMenu-showMore:hover, .ais-InfiniteHits-loadMore:focus, .ais-InfiniteHits-loadMore:hover, .ais-InfiniteHits-loadPrevious:focus, .ais-InfiniteHits-loadPrevious:hover, .ais-InfiniteResults-loadMore:focus, .ais-InfiniteResults-loadMore:hover, .ais-Menu-showMore:focus, .ais-Menu-showMore:hover, .ais-RefinementList-showMore:focus, .ais-RefinementList-showMore:hover {
  background-color: $color1
}

.ais-ClearRefinements-button--disabled, .ais-GeoSearch-redo--disabled, .ais-GeoSearch-reset--disabled, .ais-HierarchicalMenu-showMore--disabled, .ais-InfiniteHits-loadMore--disabled, .ais-InfiniteResults-loadMore--disabled, .ais-Menu-showMore--disabled, .ais-RefinementList-showMore--disabled {
  opacity: .6;
  cursor: not-allowed
}

.ais-ClearRefinements-button--disabled:focus, .ais-ClearRefinements-button--disabled:hover, .ais-GeoSearch-redo--disabled:focus, .ais-GeoSearch-redo--disabled:hover, .ais-GeoSearch-reset--disabled:focus, .ais-GeoSearch-reset--disabled:hover, .ais-HierarchicalMenu-showMore--disabled:focus, .ais-HierarchicalMenu-showMore--disabled:hover, .ais-InfiniteHits-loadMore--disabled:focus, .ais-InfiniteHits-loadMore--disabled:hover, .ais-InfiniteResults-loadMore--disabled:focus, .ais-InfiniteResults-loadMore--disabled:hover, .ais-Menu-showMore--disabled:focus, .ais-Menu-showMore--disabled:hover, .ais-RefinementList-showMore--disabled:focus, .ais-RefinementList-showMore--disabled:hover {
  background-color: $color2
}

.ais-InfiniteHits-loadPrevious--disabled, .ais-Panel--collapsible.ais-Panel--collapsed .ais-Panel-body, .ais-Panel--collapsible.ais-Panel--collapsed .ais-Panel-footer {
  display: none
}

.ais-CurrentRefinements {
  margin-top: -.3rem
}

.ais-CurrentRefinements, .ais-CurrentRefinements-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap
}

.ais-CurrentRefinements-item {
  margin-right: .3rem;
  margin-top: .3rem;
  padding: .3rem .5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: $color6;
  border-radius: 5px
}

.ais-CurrentRefinements-category {
  margin-left: .3em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex
}

.ais-CurrentRefinements-delete, .ais-CurrentRefinements-reset + .ais-CurrentRefinements-list {
  margin-left: .3rem
}

.ais-CurrentRefinements-categoryLabel, .ais-CurrentRefinements-delete, .ais-CurrentRefinements-label {
  white-space: nowrap;
  font-size: .8rem;
  color: $color16
}

.ais-CurrentRefinements-reset {
  margin-top: .3rem;
  white-space: nowrap
}

.ais-GeoSearch {
  position: relative
}

.ais-GeoSearch-control {
  position: absolute;
  top: .8rem;
  left: 3.75rem
}

.ais-GeoSearch-label {
  display: block;
  padding: .3rem .5rem;
  font-size: .8rem;
  background-color: $color16;
  border-radius: 5px;
  -webkit-transition: background-color .2s ease-out;
  transition: background-color .2s ease-out;
  -webkit-box-shadow: rgba(0, 0, 0, .1) 0 1px 1px;
  box-shadow: rgba(0, 0, 0, .1) 0 1px 1px;
  outline: 0
}

.ais-GeoSearch-input {
  margin: 0 .25rem 0 0
}

.ais-GeoSearch-label, .ais-GeoSearch-redo, .ais-GeoSearch-reset {
  white-space: nowrap
}

.ais-GeoSearch-reset {
  position: absolute;
  bottom: 1.25rem;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%)
}

.ais-HierarchicalMenu-link, .ais-Menu-link {
  display: block;
  line-height: 1.5
}

.ais-HierarchicalMenu-list, .ais-Menu-list, .ais-NumericMenu-list, .ais-RatingMenu-list, .ais-RefinementList-list {
  font-weight: 400;
  line-height: 1.5
}

.ais-HierarchicalMenu-link:after {
  margin-left: .3em;
  content: "";
  width: 10px;
  height: 10px;
  display: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27%3E%3Cpath d=%27M7.3 24l-2.8-2.8 9.3-9.2-9.3-9.2 2.8-2.8 12.2 12z%27 fill%3D%22%233A4570%22 /%3E%3C/svg%3E");
  background-size: 100% 100%
}

.ais-HierarchicalMenu-item--parent > .ais-HierarchicalMenu-link:after {
  display: inline-block
}

.ais-HierarchicalMenu-item--selected > .ais-HierarchicalMenu-link:after {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg)
}

.ais-CurrentRefinements-count, .ais-RatingMenu-count {
  font-size: .8rem
}

.ais-CurrentRefinements-count:before, .ais-RatingMenu-count:before {
  content: "("
}

.ais-CurrentRefinements-count:after, .ais-RatingMenu-count:after {
  content: ")"
}

.ais-HierarchicalMenu-count, .ais-Menu-count, .ais-RefinementList-count, .ais-ToggleRefinement-count {
  margin-left: .25rem; // KMT-mod
  font-size: .8rem;
  color: $color4;
  background-color: $color13;
  border-radius: 8px
}

.ais-HierarchicalMenu-showMore, .ais-Menu-showMore, .ais-RefinementList-showMore {
  margin-top: .5rem
}

.ais-Highlight-highlighted, .ais-Snippet-highlighted {
  background-color: $color15;
  padding: 0 // KMT-mod
}

.ais-Hits-list, .ais-InfiniteHits-list, .ais-InfiniteResults-list, .ais-Results-list {
  margin-top: -1rem;
  margin-left: -1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap
}

.ais-Panel-body .ais-Hits-list, .ais-Panel-body .ais-InfiniteHits-list, .ais-Panel-body .ais-InfiniteResults-list, .ais-Panel-body .ais-Results-list {
  margin: .5rem 0 0-1rem
}

.ais-Hits-item, .ais-InfiniteHits-item, .ais-InfiniteResults-item, .ais-Results-item {
  margin-top: 0;
  margin-left: 0;
  padding: 0;
  width: 100%;
  border: 0;
  box-shadow: none
}

.ais-Panel-body .ais-Hits-item, .ais-Panel-body .ais-InfiniteHits-item, .ais-Panel-body .ais-InfiniteResults-item, .ais-Panel-body .ais-Results-item {
  margin: .5rem 0 .5rem 1rem
}

.ais-InfiniteHits-loadMore, .ais-InfiniteResults-loadMore {
  margin-top: 1rem
}

.ais-InfiniteHits-loadPrevious {
  margin-bottom: 1rem
}

.ais-HitsPerPage-select, .ais-MenuSelect-select, .ais-NumericSelector-select, .ais-ResultsPerPage-select, .ais-SortBy-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: .3rem 2rem .3rem .3rem;
  max-width: 100%;
  background-color: $color16;
  background-image: url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27%3E%3Cpath d=%27M0 7.3l2.8-2.8 9.2 9.3 9.2-9.3 2.8 2.8-12 12.2z%27 fill%3D%22%233A4570%22 /%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 10px 10px;
  background-position: 92% 50%;
  border: 1px solid $color12;
  border-radius: 5px
}

.ais-Panel--collapsible {
  position: relative
}

.ais-Panel--collapsible .ais-Panel-collapseButton {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
  border: 0;
  background: 0 0
}

.ais-Panel-header {
  margin-bottom: .5rem;
  padding-bottom: .5rem;
  font-size: .8rem;
  font-weight: 700;
  text-transform: uppercase;
  border-bottom: 1px solid $color12
}

.ais-Panel-footer {
  margin-top: .5rem;
  font-size: .8rem
}

.ais-RangeInput-input {
  padding: 0 .2rem;
  width: 5rem;
  height: 1.5rem;
  line-height: 1.5rem
}

.ais-RangeInput-separator {
  margin: 0 .3rem
}

.ais-RangeInput-submit {
  margin-left: .3rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0 .5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  font-size: .8rem;
  color: $color16;
  background-color: $color2;
  border: 0;
  border-radius: 5px;
  -webkit-transition: .2s ease-out;
  transition: .2s ease-out;
  outline: 0
}

.ais-RangeInput-submit:focus, .ais-RangeInput-submit:hover {
  background-color: $color1
}

.ais-RatingMenu-count {
  color: $color4
}

.ais-Pagination-list {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center
}

.ais-Pagination-item + .ais-Pagination-item {
  margin-left: .3rem
}

.ais-Pagination-link {
  padding: .3rem .6rem;
  display: block;
  border: 1px solid $color12;
  border-radius: 5px;
  -webkit-transition: background-color .2s ease-out;
  transition: background-color .2s ease-out
}

.ais-Pagination-link:focus, .ais-Pagination-link:hover {
  background-color: $color14
}

.ais-Pagination-item--disabled .ais-Pagination-link {
  opacity: .6;
  cursor: not-allowed;
  color: $color9
}

.ais-Pagination-item--disabled .ais-Pagination-link:focus, .ais-Pagination-item--disabled .ais-Pagination-link:hover {
  color: $color9;
  background-color: $color16
}

.ais-Pagination-item--selected .ais-Pagination-link {
  color: $color16;
  background-color: $color2;
  border-color: $color2
}

.ais-Pagination-item--selected .ais-Pagination-link:focus, .ais-Pagination-item--selected .ais-Pagination-link:hover {
  color: $color16
}

.ais-PoweredBy-text, .ais-Stats-text, .rheostat-tooltip, .rheostat-value {
  font-size: .8rem
}

.ais-PoweredBy-logo {
  margin-left: .3rem
}

.ais-RangeSlider .rheostat-progress {
  background-color: $color6
}

.ais-RangeSlider .rheostat-background {
  border-color: $color7;
  -webkit-box-sizing: border-box;
  box-sizing: border-box
}

.ais-RangeSlider .rheostat-handle {
  border-color: $color7
}

.ais-RangeSlider .rheostat-marker {
  background-color: $color7
}

.ais-Panel-body .ais-RangeSlider {
  margin: 2rem 0
}

.ais-RangeSlider-handle {
  width: 20px;
  height: 20px;
  position: relative;
  z-index: 1;
  background: $color16;
  border: 1px solid $color5;
  border-radius: 50%;
  cursor: pointer
}

.ais-RangeSlider-tooltip {
  position: absolute;
  background: $color16;
  top: -22px;
  font-size: .8em
}

.ais-RangeSlider-value {
  width: 40px;
  position: absolute;
  text-align: center;
  margin-left: -20px;
  padding-top: 15px;
  font-size: .8em
}

.ais-RangeSlider-marker {
  position: absolute;
  background: $color8;
  margin-left: -1px;
  width: 1px;
  height: 5px
}

.ais-RatingMenu-item--disabled .ais-RatingMenu-count, .ais-RatingMenu-item--disabled .ais-RatingMenu-label {
  color: $color12
}

.ais-RatingMenu-item--selected {
  font-weight: 700
}

.ais-RatingMenu-link {
  line-height: 1.5
}

.ais-RatingMenu-link > * + * {
  margin-left: .3rem
}

.ais-RatingMenu-starIcon {
  position: relative;
  top: -1px;
  width: 15px;
  fill: $color15
}

.ais-RatingMenu-item--disabled .ais-RatingMenu-starIcon {
  fill: $color12
}

.ais-HierarchicalMenu-searchBox > *, .ais-Menu-searchBox > *, .ais-RefinementList-searchBox > * {
  margin-bottom: .5rem
}

.ais-SearchBox-form {
  display: block;
  position: relative
}

.ais-SearchBox-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: .3rem 1.7rem;
  width: 100%;
  position: relative;
  background-color: $color16;
  border: 1px solid $color12;
  line-height: 1.75rem;
  border-radius: 5px
}

.ais-SearchBox-input::-webkit-input-placeholder {
  color: $color10
}

.ais-SearchBox-input::-moz-placeholder {
  color: $color10
}

.ais-SearchBox-input:-ms-input-placeholder {
  color: $color10
}

.ais-SearchBox-input:-moz-placeholder {
  color: $color10
}

.ais-RefinementList .ais-SearchBox-input {
  line-height: 1rem
}

.ais-SearchBox-resetIcon path, .ais-SearchBox-submitIcon path {
  fill: $color6
}

.ais-VoiceSearch-button svg {
  color: currentColor
}

.ais-VoiceSearch-button:disabled:hover {
  color: $color10;
  cursor: not-allowed;
  background: inherit
}

.ais-SearchBox-submit {
  right: .3rem
}

.ais-SearchBox-loadingIndicator, .ais-SearchBox-reset, .ais-SearchBox-submit {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: absolute;
  z-index: 1;
  width: 20px;
  height: 20px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%)
}

.ais-SearchBox-loadingIndicator {
  right: .3rem
}

.ais-SearchBox-submit {
  left: .3rem
}

.ais-SearchBox-reset {
  right: .3rem
}

.ais-SearchBox-loadingIcon, .ais-SearchBox-resetIcon, .ais-SearchBox-submitIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%)
}

.ais-SearchBox-submitIcon {
  width: 14px;
  height: 14px
}

.ais-SearchBox-resetIcon {
  width: 12px;
  height: 12px
}

.ais-SearchBox-loadingIcon {
  width: 16px;
  height: 16px
}

.ais-VoiceSearch-button {
  border: 0;
  width: 24px;
  height: 24px;
  padding: 4px;
  border-radius: 50%;
  color: $color4;
  background-color: transparent
}

.ais-VoiceSearch-button:hover {
  cursor: pointer;
  background-color: $color10;
  color: $color16
}

.ais-VoiceSearch-button:disabled {
  color: $color10
}