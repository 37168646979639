// Tiffany: "While working on the calendar view of the opportunities page I made a new color scheme."
$colored-award: $red; // trophies are golden
$colored-calendar-item: $gray-900;
$colored-event: $coral-80;
$colored-source-request: $gold-60; // fire is yellow
$colored-special-report: $green;

// Tiffany: "The background color used is the same as the icon but at 15% opacity."
$colored-award-bg: transparentize($colored-award, 0.85);
$colored-calendar-item-bg: transparentize($colored-calendar-item, 0.85);
$colored-event-bg: transparentize($colored-event, 0.85);
$colored-source-request-bg: transparentize($colored-source-request, 0.85);
$colored-special-report-bg: transparentize($colored-special-report, 0.85);

.colored-award {
  color: $colored-award;
}

.colored-award-bg {
  background-color: $colored-award-bg;
}

.colored-award-border {
  border: 2px solid $colored-award;
}

.colored-calendar-item {
  color: $colored-calendar-item;
}

.colored-calendar-item-bg {
  background-color: $colored-calendar-item-bg;
}

.colored-calendar-item-border {
  border: 2px solid $colored-calendar-item;
}

.colored-event {
  color: $colored-event;
}

.colored-event-bg {
  background-color: $colored-event-bg;
}

.colored-event-border {
  border: 2px solid $colored-event;
}

.colored-source-request {
  color: $colored-source-request;
}

.colored-source-request-bg {
  background-color: $colored-source-request-bg;
}

.colored-source-request-border {
  border: 2px solid $colored-source-request;
}

.colored-special-report {
  color: $colored-special-report;
}

.colored-special-report-bg {
  background-color: $colored-special-report-bg;
}

.colored-special-report-border {
  border: 2px solid $colored-special-report;
}
