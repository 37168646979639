.striped-background-staging {
  background:
    repeating-linear-gradient(
      135deg,
      $body-bg,
      $body-bg 10px,
      tint-color($garish, 92%) 10px,
      tint-color($garish, 92%) 20px
    );
}

.striped-background-development {
  background:
    repeating-linear-gradient(
      45deg,
      $body-bg,
      $body-bg 10px,
      tint-color($warning, 88%) 10px,
      tint-color($warning, 88%) 20px
    );
}
