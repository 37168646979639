.nav-link.nav-link-beta::before {
  content: "BETA" !important;
  position: absolute;
  top: -1px;
  right: 6px;
  font-size: 50%;
  z-index: 1; /* Ensure the BETA label is above other elements */
  color: red;
  padding: 0.1rem;
  border-radius: 0.2rem;
}
