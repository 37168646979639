$transparent-ish-opacity: 0.25; // bigger numbers mean more transparent

.banner-image {
  position: relative;

  img {
    width: 100%;
  }

  .buttons-set {
    bottom: 12px;
    position: absolute;
    right: 17px;

    .btn-outline-primary {
      // background-color: rgba(92, 184, 92, 0.8);
      background-color: transparentize($primary, $transparent-ish-opacity);
      color: white;
    }
  }
}
