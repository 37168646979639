$transparent-ish-opacity: 0.55; // bigger numbers mean more transparent

.btn-primary-transparent-ish {
  border: none;
  background-color: transparentize($primary, $transparent-ish-opacity);
}

.btn-secondary-transparent-ish {
  border: none;
  background-color: transparentize($gray-300, $transparent-ish-opacity);
}

.btn-success-transparent-ish {
  border: none;
  background-color: transparentize($success, $transparent-ish-opacity);
}

.btn-info-transparent-ish {
  border: none;
  background-color: transparentize($info, $transparent-ish-opacity);
}

.btn-warning-transparent-ish {
  border: none;
  background-color: transparentize($warning, $transparent-ish-opacity);
}

.btn-danger-transparent-ish {
  border: none;
  background-color: transparentize($danger, $transparent-ish-opacity);
}
