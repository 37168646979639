// KT: Wrap in a frame div.  Specify the target width and height in a style tag on the frame div.  Like this:
//
// <div class="image-crop-round-frame" style="width: 72px; height: 72px;">
//   <div class="image-crop">
//     <img src="">
//   </div>
// </div>
//
// Based on: https://aaronparecki.com/2016/08/13/4/css-thumbnails

// TODO maybe much simpler:
// https://medium.com/@chrisnager/center-and-crop-images-with-a-single-line-of-css-ad140d5b4a87
// Except: this doesn't work in IE

%image-crop-square-frame {
  background: $white;
  display: inline-block;
  vertical-align: top;
  margin-right: 0.5em;
  margin-bottom: 0.3em;
}

.image-crop {
  height: 100%;
  overflow: hidden;
  position: relative;

  img {
    display: block;
    min-width: 100%;
    min-height: 100%;
    max-width: 100%;
    margin: auto;
    position: absolute;
    top: -100%;
    right: -100%;
    bottom: -100%;
    left: -100%;
    object-fit: cover;
  }
}

.image-crop-round-frame {
  @extend %image-crop-square-frame;

  border-radius: 50%;
  overflow: hidden;

  .image-crop {
    border-radius: 50%;
  }
}
