.card-profile-progress,
.card-profile-help {
  .progress {
    background-color: $coral-10;
    height: 5px;
  }

  .progress-profile {
    background-color: $coral-60;
    height: 5px;
  }

  h5 {
    text-transform: uppercase;
    font-size: 1rem;
  }

  p {
    margin: 0;

    a {
      font-size: 0.9rem;
    }
  }
}

.card-profile-help {
  dl {
    dt,
    dd {
      font-size: 0.9rem;
    }
  }
}
