.new-billing-account-wrapper {
  //  max-width: 800px;
  .stripe-submit:hover {
    background: $primary !important;
  }

  @include media-breakpoint-up(xl) {
    max-width: inherit;
  }

  .card {
    .card.card-billing-plan {
      min-height: auto; // Do not expand plan card height, use minimum
      margin-top: 0;
      margin-right: 0 !important;
    }

    &.plan-details {
      padding: 0.25rem 1rem !important;
      position: relative;

      ul.features-list {
        margin-top: 1rem !important;

        li {
          margin-bottom: 0.5rem;
        }
      }

      .title-box {
        height: auto !important;

        h2.mt-3 {
          display: none;
        }
      }
    }

    &.card-minimal {
      border: none !important;
      background: transparent;
      padding: 0;

      .card-header {
        background: transparent;
        border: none;
      }

      .checkout-total {
        display: block;
        margin: auto;
        float: none;
        text-align: center;
        font-weight: bold;
        font-size: 1.2rem;

        strong {
          font-size: 2rem;
          margin-left: 0.5rem;
        }

        small {
          margin-top: 10px;
          font-size: 0.9rem;
          color: $gray-500 !important;

          span {
            margin-right: 5px;
          }
        }
      }
    }

    .form-control {
      height: 40px !important;
      border: 1px solid rgb(212 208 212);
    }

    // form-control is tall so we must vertically center the Stripe element to avoid looking janky
    #card-element .form-control {
      padding-top: 10px;
      background: #fff;
    }

    .form-label {
      // font-size: 1.1rem;
      font-weight: normal;
      color: $gray-900 !important;
    }

    .billing-account-form {
      padding: 0;
    }
  }

  .coupon-badge {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    font-size: 0.85rem;
  }
}
