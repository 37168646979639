// Please only create these when necessary, note See: https://getbootstrap.com/docs/4.3/utilities/colors/

// Bootstrap doesn't have a class for *very light* gray text
.text-gray-300 {
  color: $gray-300;
}

// For gray text, we have:
// * text-secondary, text-muted -- Note text-secondary == text-muted
// * text-black-50 (gray 500)
