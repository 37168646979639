.companies-index {
  &-header {
    transition: all 0.2s;

    &:hover {
      font-weight: bold;
      border-bottom: 2px solid $orange;
    }

    &-row {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      width: 50%;
    }
  }

  &-content-container {
    display: grid;
    grid-template-columns: 20% auto;
    column-gap: 30px;
  }

  &-cards-container {
    background: white;
    padding: 2rem 1rem;
    margin-top: 3rem;
  }

  &-cards {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1.5rem;
  }

  &-sidebar {
    display: flex;
    flex-direction: column;
    background: white;
    margin-top: 3rem;
    padding: 2rem 1rem;
  }
}

.company-show {
  &-sidebar-expert-img {
    object-fit: cover;
    border-radius: 50%;
    height: 60px;
    width: 60px;
  }

  &-nav {
    background: none;
    border: none;
  }

  &-nav-link {
    color: black;
    border: none;
    transition: all 0.5s;

    &:hover {
      cursor: pointer;
      border: 1px solid $gray-600;
      border-bottom: none;
      font-weight: bold;
    }
  }

  &-nav-link.nav-link.active {
    color: black;
    font-weight: bold;
    border: none;
    border-bottom: 3px solid $brand;
    background: transparent;
  }
}

.company-card {
  transition: all 0.2s;

  &:hover {
    background-color: $gray-100;
    cursor: pointer;
    border-color: $brand;
  }
}

.company-logo-container {
  top: -60px;
}

@include media-breakpoint-down(md) {
  .company-logo-container {
    margin: auto;
    padding-top: 10px;
    transition: all 0.2s;
  }
}

@include media-breakpoint-down(sm) {
  .company-header-center-text {
    margin-top: -25px;
    padding-bottom: 0.5rem !important;
    transition: all 0.2s;
  }
}

@include media-breakpoint-down(xs) {
  .company-header-btns-container {
    display: flex;
    justify-content: center;

    // padding-top: 1rem;
    transition: all 0.2s;
  }
}

@include media-breakpoint-up(sm) {
  .company-header-btns-container {
    display: flex;
    justify-content: start;

    // padding-top: 1rem;
    transition: all 0.2s;
  }
}

.claim-this-page-box {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border: 1px solid rgb(0 0 0 / 12.5%);

  .box-header {
    background-color: $brand;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
}

.expert-hub-modal {
  max-width: 1050px;

  .features-list {
    li {
      line-height: 1rem;
    }
  }
}
