.algolia-refinementlist-horizontal {
  .ais-RefinementList-list { /* stylelint-disable-line */
    display: flex;
  }

  .ais-RefinementList-label { /* stylelint-disable-line */
    margin-right: 0.5em;
  }

  .ais-RefinementList-count { /* stylelint-disable-line */
    display: none;
  }
}

html body .unified-search {
  .ais-Hits-list { /* stylelint-disable-line */
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }

  .ais-SearchBox { /* stylelint-disable-line */
    width: 90% !important;
    max-width: 350px !important;
    display: block !important;
    margin-right: 20px !important;
  }

  .sources,
  .products {
    li.ais-Hits-item { /* stylelint-disable-line */
      margin: 0.4%;
      margin-left: 0;
    }

    @media (min-width: 1200px) {
      li.ais-Hits-item { /* stylelint-disable-line */
        width: 24%;
      }
    }

    @media (min-width: 768px) and (max-width: 1199px) {
      li.ais-Hits-item { /* stylelint-disable-line */
        width: 48%;
      }
    }

    @media (max-width: 767px) {
      li.ais-Hits-item { /* stylelint-disable-line */
        width: 98%;
      }
    }
  }

  .pitches {
    li.ais-Hits-item { /* stylelint-disable-line */
      margin: 0.4%;
      margin-left: 0;
    }

    @media (min-width: 1200px) {
      li.ais-Hits-item { /* stylelint-disable-line */
        width: 32%;
      }
    }

    @media (min-width: 768px) and (max-width: 1199px) {
      li.ais-Hits-item { /* stylelint-disable-line */
        width: 48%;
      }
    }

    @media (max-width: 767px) {
      li.ais-Hits-item { /* stylelint-disable-line */
        width: 98%;
      }
    }
  }
}
