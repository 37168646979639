/* stylelint-disable selector-class-pattern */
@import "../../../../../node_modules/fullcalendar/dist/fullcalendar.css";

.fc#calendar {
  .fc-day {
    position: relative;
  }

  // KT: takes up space on the right hand side of the calendar title row, properly centering calendar month title
  .fc-spacer-button {
    visibility: hidden;
    width: 9rem;
  }

  .fc-loadingIndicator-button {
    background: transparent;
    border: none;
    box-shadow: none;
    color: $gray-900;

    // TODO this is kinda gross
    position: absolute;
    left: 10.5rem;

    &:focus {
      outline: none;
    }
  }

  .fc-title {
    i {
      padding: 0.2rem;
    }

    // keeps popover next to label on calendar, otherwise it can randomly appear several days away
    .popover-width-fixer {
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
    }
  }

  .fc-event {
    border: none;
    cursor: pointer;

    &:hover {
      color: inherit;
    }
  }
}

// adds mild styling to table of opportunity attributes inside popover
.popover.fc {
  th,
  td {
    border: inherit;
    border-top: 1px solid $gray-300;
  }

  tr td:nth-child(1) {
    font-weight: bold;
  }
}
/* stylelint-enable selector-class-pattern */
