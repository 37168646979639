/* stylelint-disable selector-class-pattern */
.select2-container--default .select2-selection--single,
.select2-container--default .select2-selection--multiple {
  border-color: $gray-400;
  border-radius: $border-radius;
}

.select2-selection__clear {
  font-size: 32px;
}

.select2-container--default .select2-selection--single {
  height: 36px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 36px;
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: $gray-500;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  top: 5px;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: tint-color($primary, 90%);
  color: inherit;
}

.hashtags-editable .select2-container--default {
  &.select2-container--disabled {
    .select2-selection--multiple {
      background: transparent;
      border: none;

      .select2-selection__rendered {
        padding: 0;
      }
    }
  }
}

.appended-btn-container {
  border-top: 1px solid $gray-500;
  text-align: right;

  .btn {
    color: $blue;
  }
}

.select2-container {
  .matched-text {
    font-weight: bold;
  }

  .option-image {
    display: block;
    align-self: center;
    text-align: center;
    margin-right: 5px;
    width: 50px;
    height: 50px;
    max-height: 100%;

    img {
      max-height: 100%;
      max-width: 100%;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .selected-option {
    height: 36px;
    display: flex;

    .option-image {
      width: 36px;

      img {
        height: 30px;
        margin-top: 2px;
      }
    }
  }

  .option {
    display: flex;
  }

  .option-name {
    align-self: center;
  }
}
/* stylelint-enable selector-class-pattern */
