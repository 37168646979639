.ais-RangeInput { /* stylelint-disable-line -- We don't control these class names so can't make them kebab case */
  padding: 0.5em 0;

  .ais-RangeInput-submit { /* stylelint-disable-line -- We don't control these class names so can't make them kebab case */
    text-indent: -9999px;
    line-height: 0;
    padding: 0 0.75em;
    background: #ebebeb;
    border: 1px solid #cbc5c9;
    margin-left: 0.75em;

    &::after {
      content: "Go";
      display: block;
      line-height: initial;
      text-indent: 0;
    }

    &:hover {
      background: #777;
      color: white;
    }
  }
}
