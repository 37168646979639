$privileged-color: $garish;

.bg-staff {
  background-color: $privileged-color;
}

.border-staff {
  border: 2px solid lighten($privileged-color, 20%);
}

// To be used for labels of staff-accessible inputs that are in a forms shared with both internal and external users
.label-staff {
  color: $privileged-color;
  font-weight: bold;

  &::after {
    content: " 🔓";
  }
}

.text-staff {
  color: $privileged-color;
}

.alert-staff {
  background-color: lighten($privileged-color, 60%);
  border-color: lighten($privileged-color, 50%);
  color: $privileged-color;
}

.btn-staff {
  background-color: $privileged-color;
  color: $white;

  &:hover {
    background-color: darken($privileged-color, 10%);
    color: $white;
  }
}

.nav-pills.nav-pills-staff .nav-link {
  color: $privileged-color;
}

.nav-pills .nav-link.nav-link-staff.active {
  color: $white;
  background: $privileged-color none;
}

.nav-pills.nav-pills-staff .nav-link.active {
  background: $privileged-color none;
  color: #fff;
}

.custom-navbar.navbar .nav-link-staff.nav-link.active {
  &::after {
    background-color: $privileged-color;
  }
}

.dropdown-menu-staff {
  .dropdown-item {
    &:active,
    &.active {
      background-color: $privileged-color;
    }
  }
}

hr.hr-staff {
  border-top: 1px solid $privileged-color;
}
