$banner-bg-scale: 1% !default;
$banner-border-scale: 100% !default;
$banner-border-width: 0 !default;
$banner-color-scale: 1% !default;
$banner-link-font-weight: $font-weight-bold !default;

// Mixin for horizontal gradient background
@mixin gradient-bg-horizontal($background) {
  background: linear-gradient(to right, $background, rgba($background, 0.75));
}

// Mixin for banner variants
@mixin banner-variant($background, $border, $color) {
  color: $color !important;

  @include gradient-bg-horizontal($background);

  border-color: $border;

  .banner-link {
    color: color-contrast($background);
    text-decoration: underline;
  }
}

// General banner styles
.banner {
  display: block;
  padding: 1.125rem;
  font-weight: bold;
  text-align: center;
  border: $banner-border-width solid transparent;
}

// Provide class for links that match banners
.banner-link {
  font-weight: $banner-link-font-weight;
}

// Generate banner styles for each theme color
@each $state, $value in $theme-colors {
  $banner-background: shift-color($value, $banner-bg-scale);
  $banner-border: shift-color($value, $banner-border-scale);
  $banner-color: shift-color($value, $banner-color-scale);

  @if contrast-ratio($banner-background, $banner-color) < $min-contrast-ratio {
    $banner-color: mix($value, color-contrast($banner-background), abs($banner-color-scale));
  }

  .banner-#{$state} {
    @include banner-variant($banner-background, $banner-border, $banner-color);
  }
}
