@mixin avatar($size) {
  width: $size;
  height: $size;
  border-radius: 50%;
  text-align: center;
  position: relative;
  overflow: hidden;
  margin: 0;
  font-family: proxima-nova, "Helvetica Neue", Arial, sans-serif;
  font-weight: bold;

  img {
    height: 100%;
  }

  .initials {
    position: relative;
    top: $size * 0.25;
    font-size: $size * 0.55;
    line-height: $size * 0.5;
    color: white;

    img {
      height: $size * 0.5;
    }
  }
}

.avatar {
  @include avatar(80px);
}

.avatar-xs {
  @include avatar(33px);

  .initials {
    top: 7px;
  }
}

.avatar-sm {
  @include avatar(50px);
}

.avatar-md {
  @include avatar(100px);
}

.avatar-lg {
  @include avatar(160px);
}

.avatar.rounded-circle {
  border-radius: 50%;
}

.avatar-framed {
  border: 2px solid $white;
  box-shadow: inset 0 1.5px 3px 0 rgb(0 0 0 / 15%), 0 1.5px 3px 0 rgb(0 0 0 / 15%);
}

.avatar-square {
  border-radius: 4px;
}

.avatar-responsive-aspect-ratio {
  width: 100%;
  height: 100%;
  max-height: 25rem;
  object-fit: cover;
  border-radius: 7px;
}

.avatar-responsive {
  @include avatar(80px);

  width: 100%;
  height: auto;
  object-fit: contain;
  max-height: 25rem;
  display: block;
  border-radius: 7px;
}

.avatar-responsive-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  border-radius: 7px;
}

.avatar-responsive-container::before {
  content: "";
  display: block;
  padding-top: 100%;
}

.avatar-responsive-empty {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  color: white;
  font-family: proxima-nova, "Helvetica Neue", Arial, sans-serif;
  font-weight: bold;
  font-size: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.avatar-change-text {
  background: $gray-600;
  bottom: 0;
  color: $white;
  display: none;
  height: 40px;
  line-height: 32px;
  right: 0;
  width: 100%;
  z-index: 3;
  font-weight: 700;
  text-align: center;
  left: 0;
  position: absolute;
}

.avatar-sm .avatar-change-text {
  font-size: 12px;
}

.avatar.is-editable:hover .avatar-change-text {
  display: block;
  text-decoration: underline;
}

.letter-a {
  background-color: rgb(226 95 81);
}

.letter-b {
  background-color: rgb(242 96 145);
}

.letter-c {
  background-color: rgb(187 101 202);
}

.letter-d {
  background-color: rgb(149 114 207);
}

.letter-e {
  background-color: rgb(120 132 205);
}

.letter-f {
  background-color: rgb(91 149 249);
}

.letter-g {
  background-color: rgb(72 194 249);
}

.letter-h {
  background-color: rgb(69 208 226);
}

.letter-i {
  background-color: rgb(72 182 172);
}

.letter-j {
  background-color: rgb(82 188 137);
}

.letter-k {
  background-color: rgb(155 206 95);
}

.letter-l {
  background-color: rgb(212 227 74);
}

.letter-m {
  background-color: rgb(254 218 16);
}

.letter-n {
  background-color: rgb(247 192 0);
}

.letter-o {
  background-color: rgb(255 168 0);
}

.letter-p {
  background-color: rgb(255 138 96);
}

.letter-q {
  background-color: rgb(194 194 194);
}

.letter-r {
  background-color: rgb(143 164 175);
}

.letter-s {
  background-color: rgb(162 136 126);
}

.letter-t {
  background-color: rgb(163 163 163);
}

.letter-u {
  background-color: rgb(175 181 226);
}

.letter-v {
  background-color: rgb(179 155 221);
}

.letter-w {
  background-color: rgb(194 194 194);
}

.letter-x {
  background-color: rgb(124 222 235);
}

.letter-y {
  background-color: rgb(188 170 164);
}

.letter-z {
  background-color: rgb(173 214 125);
}

.letter-number {
  background-color: rgb(236 229 248);
}

.letter-unknown {
  background-color: rgb(178 242 247);
}

.letter-a-text {
  color: rgb(226 95 81);
}

.letter-b-text {
  color: rgb(242 96 145);
}

.letter-c-text {
  color: rgb(187 101 202);
}

.letter-d-text {
  color: rgb(149 114 207);
}

.letter-e-text {
  color: rgb(120 132 205);
}

.letter-f-text {
  color: rgb(91 149 249);
}

.letter-g-text {
  color: rgb(72 194 249);
}

.letter-h-text {
  color: rgb(69 208 226);
}

.letter-i-text {
  color: rgb(72 182 172);
}

.letter-j-text {
  color: rgb(82 188 137);
}

.letter-k-text {
  color: rgb(155 206 95);
}

.letter-l-text {
  color: rgb(212 227 74);
}

.letter-m-text {
  color: rgb(254 218 16);
}

.letter-n-text {
  color: rgb(247 192 0);
}

.letter-o-text {
  color: rgb(255 168 0);
}

.letter-p-text {
  color: rgb(255 138 96);
}

.letter-q-text {
  color: rgb(194 194 194);
}

.letter-r-text {
  color: rgb(143 164 175);
}

.letter-s-text {
  color: rgb(162 136 126);
}

.letter-t-text {
  color: rgb(163 163 163);
}

.letter-u-text {
  color: rgb(175 181 226);
}

.letter-v-text {
  color: rgb(179 155 221);
}

.letter-w-text {
  color: rgb(194 194 194);
}

.letter-x-text {
  color: rgb(124 222 235);
}

.letter-y-text {
  color: rgb(188 170 164);
}

.letter-z-text {
  color: rgb(173 214 125);
}

.letter-number-text {
  color: rgb(236 229 248);
}

.letter-unknown-text {
  color: lightgray;
}

.m-0-auto {
  margin: 0 auto !important;
}
