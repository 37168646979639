// TODO: KT can these be abstracted more, out of the 'pages' folder?

.opportunities {
  &.show {
    // TODO: not convinced this is used at all
    .organization-card {
      img {
        width: 70px;
        float: left;
        margin-right: 20px;
      }
    }

    // TODO: this must have to do with the twitter feed or the map?
    .card-body {
      iframe {
        width: 100%;
      }
    }

    // TODO: this is the 'info' section in the upper center, and it can be turned into a bootstrap 4 list group
    .info-card .card-body {
      padding: 0;

      table {
        margin-bottom: 0;

        tr {
          td {
            small {
              font-size: 0.875rem;
              color: $gray-500;
            }
          }

          &:not(:last-child) {
            border-bottom: 0.5px solid $gray-200;
          }
        }
      }
    }
  }

  &.index {
    // styles that apply only to opportunities/index page

    .opportunity-index-card {
      .card-header,
      .card-body {
        padding: 0.5rem 1rem;
      }
    }
  }
}
