.pub-logo {
  @media (max-width: 767px) {
    left: 5px;
    bottom: 5px;

    a {
      max-width: 75px !important;
    }
  }

  @media (min-width: 768px) {
    top: 65px;
    right: 10px;
  }
}

.source_requests.show { /* stylelint-disable-line */

  [aria-expanded="false"] > .expanded,
  [aria-expanded="true"] > .collapsed {
    display: none;
  }

  .btn-new-source,
  .btn-not-specified {
    font-size: 32px;
  }

  .card.info-card {
    .free-pitch-badge {
      width: 6.25%;
      min-width: 50px;
      max-width: auto;
    }

    .sr-logo {
      max-width: 150px;
    }
  }

  .pitches-container {
    .search-icon {
      padding-left: 36px;
      background: url("../assets/images/search-icon-inactive.png") 10px center no-repeat;
      background-size: auto 60%;

      &:focus {
        background-image: url("../assets/images/search-icon-active.png");
      }
    }

    .pitches-list {
      max-height: 250px;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
    }
  }
}
