/* stylelint-disable selector-class-pattern */
#select-hashtags {
  > div[data-react-class] {
    height: 200px;
    border: 1px solid $gray-400;
    border-radius: 5px;
    background-color: #fff;
  }

  .select2-container {
    max-width: 100%;
  }

  .select2-container--default .select2-selection--multiple,
  .select2-container--default.select2-container--focus .select2-selection--multiple,
  .select2-container--default .select2-selection--single {
    border-color: transparent;
  }
}
/* stylelint-enable selector-class-pattern */
