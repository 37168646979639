.timeline-wrapper {
  position: relative;
  height: 20px;
}

.timeline-wrapper .progress {
  background-color: $gray-200;
}

// the timeline tics bar is absolutely positioned on top of the regular progress bar
.timeline-tics-bar {
  display: flex;
  height: 1rem;
  position: absolute;
  top: 0;
  width: 100%;
  background-color: transparent;
}

.timeline-tic {
  background-color: transparent;
  border-right: 1px solid $gray-500;
  display: flex;
}

.timeline-tic-highlight {
  border-width: 2px;
  border-color: black;
}

.timeline-annotation-wrapper {
  display: flex;
}

.timeline-annotation-bar {
  background-color: transparent;
  height: 4em;
  display: flex;
  overflow: hidden;
}

.timeline-annotation-push {
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: unset;
  overflow: visible;
  text-align: left;
  white-space: normal;
}

.timeline-annotation {
  font-size: 14px;
  color: $gray-500;
}

.timeline-annotation-highlight {
  color: inherit;
}

.timeline-annotation-primary {
  color: $brand;
  font-size: 14px;

  @include media-breakpoint-up(lg) {
    font-size: 16px;
  }
}
