.source_represented_sources { /* stylelint-disable-line */
  button:focus,
  button:active {
    outline: 0 !important;
    border: none !important;
    box-shadow: none !important;
  }

  .page-header {
    padding-left: 25px;

    .font-weight-bold {
      font-weight: bold;
    }

    display: inline;
    width: auto;
  }

  h5 {
    float: right;
    text-align: right;
    margin-top: -20px;

    a {
      margin-left: 15px;
      margin-top: -10px;
    }
  }

  .reps-search {
    h5 {
      float: left;
      text-align: left;
    }

    /*** custom checkboxes ***/
    input[type="checkbox"] {
      position: relative;
    }

    .rep-btn {
      position: absolute;
      right: 7px;
      bottom: 7px;
    }

    .rep-list {
      border-top: 1px solid gainsboro;
      padding-top: 15px;
      padding-bottom: 0;

      input[type="checkbox"],
      i.text-info {
        display: none;
      }

      ul {
        padding: 0;
        border-bottom: 1px solid gainsboro;
      }

      ul,
      li {
        list-style-type: none;
      }

      li {
        font-weight: bold;
        font-size: 1.2em;

        button {
          font-weight: normal;
        }

        p {
          font-size: 0.9em;
          font-weight: normal;
        }
      }

      .rep-btn {
        top: 0.7em;

        .no-btn {
          display: none;
        }

        &.remove-rep-btn {
          button {
            display: inline;
          }

          .no-btn {
            display: none;
          }
        }
      }

      h2 {
        font-size: 1em;
      }
    }

    /* to hide the checkbox itself */
    label > input[type="checkbox"]::before {
      position: absolute;
      background: #f9f9f9;
      height: auto;
      width: auto;
      font-family: "Font Awesome 6 Free", sans-serif;
      display: inline-block;
      content: "\f0c8";
      letter-spacing: 10px;
      font-size: 1.5em;
      color: rgb(80 138 227);
      float: left;
      box-sizing: border-box;
    }

    /* space between checkbox and label */
    label > input[type="checkbox"]:checked::before {
      font-family: FontAwesome, sans-serif;
      display: inline-block;
      content: "\f14a";
      letter-spacing: 5px;
      font-size: 1.5em;
      float: left;
      height: auto;
      width: auto;
      color: rgb(80 138 227);
    }

    /* stylelint-disable selector-class-pattern */

    /* end custom checkboxes */
    .ais-ToggleRefinement {
      height: auto;
    }

    .ais-ToggleRefinement-checkbox {
      float: left;
    }

    .ais-Panel-header {
      border: none;
    }

    .ais-ToggleRefinement-labelText {
      font-size: 1em;
      margin-left: 15px;
      margin-top: 8px;
      font-weight: bold;
      float: left;
    }
    /* stylelint-enable selector-class-pattern */

    .user-card {
      .card-body {
        i.no-btn {
          position: absolute;
          bottom: 7px;
          right: 7px;
          font-size: 1.7em;
        }

        .rep-btn {
          bottom: 7px;
          right: 7px;
        }

        .company-name {
          font-weight: bold;
          font-size: 1.3em;
        }

        span.check-label {
          float: left;
          margin-right: 10px;
          margin-top: 4px;
          padding-bottom: 0;
        }

        h3.name-bar {
          margin-left: -25px;
          background: rgb(234 234 234);
          color: #000;
          font-weight: bold;
          font-size: 1.3em;
        }

        .avatar-responsive-container {
          border-radius: 0;
        }
      }
    }
  }
}
