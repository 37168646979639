.card-billing-plans-container {
  .cc-cancel-button {
    width: 120px;

    &:hover {
      background: $periwinkle-60 !important;
      color: #fff !important;
    }
  }

  .button-block {
    .btn {
      &:hover {
        background: $periwinkle-80 !important;
        color: #fff !important;
      }
    }
  }

  img.plan-icon {
    width: 37%;
  }

  // Otherwise, use normal display and spacing
  .title-box {
    height: 15rem;
    padding-top: 2.3rem;
    text-align: center;
    border-radius: 0.25rem 0.25rem 0 0;
    background-clip: padding-box;
    z-index: 99;

    h2 {
      font-size: 2rem;
      font-weight: bold;
    }

    h4 {
      font-size: 1.2rem;
      height: 3.75rem;
      font-weight: 400;
    }

    a.btn,
    button.btn {
      border-radius: 1.25rem;
      margin-top: 0.5rem;
      width: 85%;
    }
  }

  // Hide elements and adjust spacing when this class applied
  &.no-buttons-or-footer {
    .title-box {
      height: 14.25rem;
    }

    .button-block {
      display: none;
    }

    .card-body {
      min-height: 0;
    }
  }

  // Pro is taller, only if it is in the 2nd position -- this helps when the "legacy" plan
  // renders and there are 4 cards across
  :nth-child(2) {
    &.card.card-billing-plans-pro {
      margin-top: 0;
    }
  }

  .card.card-billing-plan {
    flex: 1;
    border: none;
    min-height: 18rem;
    margin-top: 1rem;

    &.card-billing-plans-basic {
      background-color: $gold-20;
    }

    &.card-billing-plans-pro {
      background-color: $matcha-20;
      min-height: 20rem;
    }

    &.card-billing-plans-enterprise {
      background-color: $periwinkle-10;

      h2.mt-3 {
        font-size: 1.75rem !important;
        margin: auto 15px;
      }
    }

    .card-body {
      ul.features-list {
        list-style-type: none;
        margin-top: 5rem;

        li {
          font-weight: bold;

          // Make the check lightly bigger & lighter
          i {
            font-size: 1.25rem;
            opacity: 0.7;
          }
        }
      }
    }
  }
}
/* stylelint-disable-next-line selector-class-pattern */
.billing_accounts.plans {
  .card-billing-plans-pro {
    @include media-breakpoint-up(md) {
      margin-right: 1.5rem;
      margin-left: 1.5rem;
    }
  }

  .card-billing-plans-container {
    .card-billing-plan {
      @include media-breakpoint-up(md) {
        max-width: 50%;
      }
    }
  }
}
