.my-messaging-contact {
  margin-right: 20px;
  margin-bottom: 15px;
  width: 80px;

  &:hover {
    text-decoration: none;
  }

  .my-messaging-contact-name {
    margin-top: 7px;
    font-size: 14px;
    text-align: center;
  }
}
