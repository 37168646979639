.source_requests { /* stylelint-disable-line */
  .ais-SearchBox { /* stylelint-disable-line -- We don't control these class names so can't make them kebab case */
    width: 100%;

    @media (min-width: 892px) {
      width: 70%;
    }

    form.ais-SearchBox-form { /* stylelint-disable-line -- We don't control these class names so can't make them kebab case */
      width: 100%;

      .ais-SearchBox-reset { /* stylelint-disable-line -- We don't control these class names so can't make them kebab case */
        display: flex;
      }
    }
  }
}
