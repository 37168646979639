@import "~bootstrap/scss/mixins";

$check-input-container-width: 30px;

.sign-up-page {
  .footer {
    height: 15rem;
  }

  .share-work-header {
    background-image: none;
  }

  @include media-breakpoint-up(md) {
    .share-work-header {
      background-image: url("../assets/images/share_work.png");
      background-repeat: no-repeat;
      background-position: left top;
      min-height: 300px;
      background-size: 16rem;
    }
  }

  #error-explanation {
    ul {
      list-style: none;

      li {
        margin-bottom: 15px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.sign-up-section {
  .welcome-title {
    margin-left: $check-input-container-width;
  }

  .header-signup {
    font-weight: 700;
    font-size: 34px;
  }

  .subheader-signup {
    font-size: 20px;
    font-weight: 400;
  }

  .user-type-row {
    cursor: pointer !important;

    label,
    input {
      cursor: pointer !important;
    }

    label {
      margin-bottom: 0;
    }

    border-width: 2px;

    &.selected {
      background: transparentize($primary, 0.85) !important;
      border: 2px solid $primary;
    }

    &:hover {
      background: transparentize($primary, 0.95) !important;
      border: 2px solid $primary;
    }
  }

  .expanding-row {
    .form-text {
      padding-left: 15px;
    }

    .user-type-row {
      padding: 15px;
      width: 100%;
    }
  }

  .form-check {
    .form-text {
      padding-left: 8px;
    }
  }

  .h2-subtypes {
    font-size: 16px;
    font-weight: 400;
    color: $black;
  }
}

.select2-add-company-modal {
  max-width: 580px;

  .company {
    display: flex;
    margin-bottom: 1.75rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .company-name {
    align-self: center;
    margin-left: 0.5rem;
  }

  .company-image {
    align-self: center;
    text-align: center;
    margin-right: 5px;
    width: 50px;
    max-height: 50px;

    img {
      max-height: 100%;
      max-width: 100%;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.check-input-container {
  flex: 0 0 $check-input-container-width;
}

// more rounded corners for use in user types registration pages
.btn-rounded {
  border-radius: 3em;
}

// toggleable buttons on registration
.btn-usertype {
  color: $black;
  background-color: $white;
  border: 1px solid $gray-200;
  font-size: 16px;
  font-weight: 400;

  // TODO: there might be a way to do this besides spamming !important.
  &:not(.disabled).active,
  &:not(:disabled):active {
    color: $black !important;
    border: 1px solid $primary !important;
    box-shadow: none !important;
    background-color: tint-color($primary, 90%);
  }
}

// social login buttons
.btn-social-login {
  font-size: 1rem;
  font-weight: bold;
  color: $white;

  &:hover {
    color: $white;
  }
}

.btn-twitter-login {
  background-color: $twitter-blue;
}

.btn-signup-email {
  // font-weight: bold;
  font-size: 16px;
  font-weight: 600;
}

.btn-signup-twitter {
  font-size: 16px;
  font-weight: 600;
}

.post-signup-capture-interests {
  h1,
  h2 {
    font-weight: 700;
  }

  h1 {
    font-size: 26px;
  }

  h2 {
    font-size: 16px;

    span {
      font-weight: lighter;
    }
  }

  .post-signup-tags-input {
    width: 100%;
    border: 2px solid $gray-200;
  }
}
