// Bootstrap 4 ships with a _custom.scss file for easy overriding of default variables
// in /scss/_variables.scss. Copy and paste relevant lines from there into the
// _custom.scss file, modify the values, and recompile your Sass to change our
// default values. Be sure to remove the flag from override values.

// I.e. this file overrides:
// @import "bootstrap/variables";

// Table of Contents
//
// Color system
// Options
// Spacing
// Body
// Links
// Paragraphs
// Grid breakpoints
// Grid containers
// Grid columns
// Components
// Typography
// Tables
// Buttons + Forms
// Buttons
// Forms
// Form validation
// Z-index master list
// Navs
// Navbar
// Dropdowns
// Pagination
// Cards
// Tooltips
// Popovers
// Toasts
// Badges
// Modals
// Alerts
// Progress bars
// List group
// Image thumbnails
// Figures
// Breadcrumbs
// Carousel
// Spinners
// Close
// Code
// Utilities
// Printing

// -----
// KT IMPORTANT: Please keep the overrides that you bring over from "bootstrap/variables"
// in the same order they are within that file.
// When overriding a variable, please comment out the original value, directly above the modified value.

// ----- KT -- WHEN UPGRADING BOOTSTRAP, RE-PORT THIS FILE (except the colors)
// OVER FROM the Bootstrap source, at: node_modules/bootstrap/scss/_variables.scss

// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system

@import "colors";

// Options

// Spacing

// Body

// $body-bg: $white !default;
$body-bg: $gray-100;

// $body-color: $gray-900 !default;
$body-color: $gray-800;

// Utilities maps

// Links
//
// Style anchor elements.

$link-decoration: none !default;

// $link-color:                              $primary !default;
$link-color: $periwinkle-70 !default;

// Paragraphs

// Grid breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  xxxl: 1600px,
  xxxxl: 1800px
);

// Grid containers

// Grid columns

// Components

// $border-radius:               .25rem !default;
$border-radius: 7px !default;

// Typography

// $font-family-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue",
// Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
// "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-family-sans-serif: "proxima-nova", "Helvetica Neue", arial, sans-serif !default;

// $font-family-base: var(--#{$variable-prefix}
// Following is how we had overriden it in Bootstrap 4, but let's try to undo this, so I am commenting it out for now
// $font-family-base: $font-family-sans-serif;

// $line-height-base: 1.5 !default;
$line-height-base: 1.375 !default;

// $h1-font-size: $font-size-base * 2.5 !default;
$h1-font-size: 2rem !default;

// $h2-font-size: $font-size-base * 2 !default;
$h2-font-size: 1.71rem !default;

// $h3-font-size: $font-size-base * 1.75 !default;
$h3-font-size: 1.5rem !default;

// $h4-font-size: $font-size-base * 1.5 !default;
$h4-font-size: 1.15rem !default;

// $h5-font-size: $font-size-base * 1.25 !default;
$h5-font-size: 1.07rem !default;

// $h6-font-size: $font-size-base !default;
$h6-font-size: 1rem !default;

// Tables

// Buttons + Forms

// Buttons

// Forms

// $input-bg: $body-bg !default;
$input-bg: $white !default;

// Form validation

// Z-index master list

// Navs

// Navbar

// $navbar-light-color: rgba($black, .55) !default;
$navbar-light-color: rgba($black, 0.6) !default;

// Dropdowns

$dropdown-link-color: $gray-600 !default;

// Pagination

// Placeholders

// Cards

// $card-spacer-y: $spacer !default;
$card-spacer-y: 0.5rem !default;

// $card-spacer-x: $spacer !default;
$card-spacer-x: 1rem !default;

// Accordion

// Tooltips

// See also: additions/custom-tooltip.scss
$tooltip-color: $primary !default;
$tooltip-bg: $white !default;
$tooltip-opacity: 1 !default;

// Popovers

// Toasts

// Badges

// Modals

// Alerts

// Progress bars

// List group

// Image thumbnails

// Figures

// Breadcrumbs

// $breadcrumb-padding-y: 0 !default;
$breadcrumb-padding-y: 0.5rem !default;

// $breadcrumb-padding-x: 0 !default;
$breadcrumb-padding-x: 0 !default;

// $breadcrumb-bg: null !default;
$breadcrumb-bg: none !default;

// Carousel

// Spinners

// Close

// Offcanvas

// Code
