/* stylelint-disable selector-class-pattern */
@import "../../../../../node_modules/flatpickr/dist/flatpickr.css";

// Note ability to make things oranger: https://flatpickr.js.org/themes/
// @import '../../../../../node_modules/flatpickr/dist/themes/material_orange.css';

// flatpicker marks its fields as "readonly" which, then Bootstrap makes them gray -- which, makes them look disabled
.input-group.flatpickr input.flatpickr-input:read-only {
  background-color: white;
}

.input-group.flatpickr .input-group-append {
  cursor: pointer;
}

// NB: "Calendar with time lacks visual cue for next action"
// https://github.com/flatpickr/flatpickr/issues/413
.flatpickr-confirm {
  background-color: $primary;
  color: $white;
  font-size: large;
  cursor: pointer;
}

.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: $primary;
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background: $primary;
  box-shadow: none;
  color: $white;
  border-color: $primary;
}

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
  box-shadow: -10px 0 0 $primary;
}

.flatpickr-day.week.selected {
  border-radius: 0;
  box-shadow: -5px 0 0 $primary, 5px 0 0 $primary;
}
/* stylelint-enable selector-class-pattern */
