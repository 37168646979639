/* www.cssportal.com/css-ribbon-generator */

.ribbon {
  position: absolute;
  right: -5px;
  top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 75px;
  height: 75px;
  text-align: right;
}

.ribbon span {
  font-size: 10px;
  font-weight: bold;
  color: rgb(0 0 0 / 50%);
  text-align: center;
  line-height: 20px;
  transform: rotate(45deg);
  width: 100px;
  display: block;
  background: rgb(0 0 0 / 1%);
  background: linear-gradient(rgb(0 0 0 / 0%) 0%, rgb(0 0 0 / 5%) 100%);
  box-shadow: 0 3px 10px -5px rgb(0 0 0 / 50%);
  position: absolute;
  top: 19px;
  right: -21px;
}

.ribbon span::before {
  content: "";
  position: absolute;
  left: 0;
  top: 100%;
  z-index: -1;
  border-left: 3px solid rgb(0 0 0 / 10%);
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid rgb(0 0 0 / 10%);
}

.ribbon span::after {
  content: "";
  position: absolute;
  right: 0;
  top: 100%;
  z-index: -1;
  border-left: 3px solid transparent;
  border-right: 3px solid rgb(0 0 0 / 2%);
  border-bottom: 3px solid transparent;
  border-top: 3px solid rgb(0 0 0 / 1%);
}
