// Relevant: https://getbootstrap.com/docs/5.3/components/tooltips/#custom-tooltips
.tooltip {
  --bs-tooltip-bg: var(--bs-white);

  // NB: in the source, the border is set to --bs-tooltip-bg, so we must override it in this clumsy way here
  --custom-tooltip-bg-border-color: lightgrey;

  .tooltip-inner {
    border: 1px solid var(--custom-tooltip-bg-border-color);
  }
}

// Turn the tooltip arrow off entirely, as it is difficult to control its border color
.tooltip-arrow::before {
  border-color: transparent !important;
}
