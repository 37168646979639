/* stylelint-disable max-line-length */
.reporter-profile-container {
  .dropdown-toggle-chevron-caret {
    text-align: left;
    padding: 0.25rem 1.5rem;
  }

  .dropdown-toggle-chevron-caret::after {
    float: right;
    margin-top: 10px;
  }

  @media screen and (max-width: 828px) {
    .personal-info {
      border-right: none !important;
    }
  }

  .personal-info .edit-full-name-btn {
    font-size: 0.5em;
    margin-top: 0.25em;
  }

  .reporter-modal .input-actions {
    width: 50px;
    padding-top: 10px;
  }

  @media screen and (max-width: 767px) {
    .avatar-info .avatar {
      margin: 0 auto;
    }
  }

  .twitter-feed {
    .card {
      border-radius: 0;
    }

    .card-header {
      background-color: transparent;
      border-bottom: none;
      font-size: 1.5rem;
      padding: 1rem 1rem 0;
    }

    .card-body {
      padding: 1rem !important;
      overflow: hidden;

      // Attempt to fix twitter overflowing on mobile:
      // https://stackoverflow.com/questions/23083462/how-to-get-an-iframe-to-be-responsive-in-ios-safari/23083463#23083463
      iframe {
        width: 100vw;
        min-width: 100% !important;
      }
    }
  }

  .avatar-container {
    position: absolute;
    top: -100px;
  }

  .avatar-info {
    h2 {
      margin-top: 90px;
    }
  }
}

// NOTE: This make google address autocomplete results to appear above the modal.
.pac-container {
  z-index: 9999;
}

.sortable-item {
  display: inline-block;
  color: black;
  z-index: 9999;
}

.content-tab > .tab-links > ul > li:focus,
.content-tab > .tab-links > ul > li > span:focus {
  outline: none;
}

.hashtags-form-modal {
  .featured-hashtag {
    background-color: $yellow;
  }

  .other-hashtag {
    background-color: $gray-100;
  }

  // NOTE: Try to prevent text is selected while dragging on the hashtag modal
  .noselect {
    -webkit-touch-callout: none; /* iOS Safari */ /* Safari */ /* Konqueror HTML */ /* Firefox */ /* Internet Explorer/Edge */
    user-select: none;

    /* Non-prefixed version, currently
                                       supported by Chrome and Opera */
  }
}
/* stylelint-enable max-line-length */
