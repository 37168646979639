@media (max-width: 768px) {
  .img-max-size {
    max-width: 100px;
    max-height: 100px;
  }

  .float-end-hide {
    float: none !important;
  }
}
