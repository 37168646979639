#card-open-requests {
  .card-footer {
    background-color: $white;
  }

  #list-open-requests {
    dl {
      dt {
        text-transform: uppercase;
        color: $gray-500;
        font-weight: 500;
        font-size: 14px;
      }

      dt.open-request-type {
        color: $gray-600;
      }

      dd {
        a {
          font-size: 16px;
        }
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  ul.open-request-actions li {
    text-align: center;
  }

  #list-open-requests {
    dl:not(.open-request-title) {
      display: flex;
      justify-content: space-between;
    }
  }
}

@include media-breakpoint-up(sm) {
  ul.open-request-actions li {
    text-align: right;
  }
}
