/* stylelint-disable selector-class-pattern */
/* stylelint-disable selector-id-pattern */
@media screen and (max-width: 767px) {
  #CybotCookiebotDialog {
    max-height: 45% !important;
    font-size: 14px !important;
    bottom: 0 !important;
    left: 10px !important;
    position: fixed !important;
    transform: none !important;
  }

  #CybotCookiebotDialogBodyContent {
    font-size: 12px !important;
  }

  .CybotCookiebotDialogBodyButton {
    display: inline-block !important;
    margin-right: 10px;
    padding: 1px !important;
    font-size: 12px !important;
  }

  .CybotCookiebotDialogBodyButtonWrapper {
    display: flex !important;
    justify-content: center;
    flex-wrap: wrap;
  }
}
/* stylelint-enable selector-class-pattern */
/* stylelint-enable selector-id-pattern */
