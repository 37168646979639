// Table of Contents
//
// Colors
// Options
// Spacing
// Body
// Links

// on hover underline links without a class attribute. avoids on-hover-underlining buttons
// https://stackoverflow.com/questions/65480036/bootstrap-5-underline-default-changed
a:not([class]):hover {
  text-decoration: underline;
}

// Grid breakpoints
// Grid containers
// Grid columns
// Fonts
// Components
// Tables
// Buttons

.btn-close {
  border-radius: 50%;

  &:hover {
    background-color: tint-color($primary, 90%);
  }
}

// Forms
// Dropdowns
// Z-index master list
.navbar.custom-navbar {
  // .sticky-top is set to 1020. set navbar menu items to 1021. narrow down selection
  // for .dropdown-menu only under .custom-navbar
  .dropdown-menu {
    z-index: 1021;
  }
}

// Navbar
// Navs
// Pagination
// Form states and alerts
// Cards
// Tooltips
// Popovers
// Badges
.navbar-brand {
  margin-right: 50px;
}

// Modals

// will have to examine this. doesn't happen on BS4 but BS5 adds an extra padding: 15px
// on body which is supposedly for the scrollbar
// but why does it add an extra beyond that?
.modal-open {
  padding-right: 0 !important;
}

// Alerts
// Progress bars
// List group
// Image thumbnails
// Figures
// Breadcrumbs
// Carousel
// Close
// Code
// Position
// Background
// Gradients

// -----
// IMPORTANT: Please sort additions into an order that corresponds with the "Table of Contents" brought over from
// Bootstrap.

// Color system

.bg-gray-300 {
  background: $gray-300;
}

.bg-info-light {
  // background-color: transparentize(theme-color("info"), 0.55);
  background-color: $tbn-20;
}

.bg-light-blue {
  background-color: #5590f5;
}

.opacity-0 {
  opacity: 0;
}

.opacity-10 {
  opacity: 0.1;
}

.opacity-20 {
  opacity: 0.2;
}

.opacity-30 {
  opacity: 0.3;
}

.opacity-40 {
  opacity: 0.4;
}

.opacity-50 {
  opacity: 0.5;
}

.opacity-60 {
  opacity: 0.6;
}

.opacity-70 {
  opacity: 0.7;
}

.opacity-80 {
  opacity: 0.8;
}

.opacity-90 {
  opacity: 0.9;
}

.opacity-100 {
  opacity: 1;
}

// Options
// Spacing
// Body
// Links
// Paragraphs
// Grid breakpoints
// Grid containers
// Grid columns
// Components
// Typography
// Tables
// Buttons + Forms
// Buttons

.btn {
  // border: none;
  font-family: $font-family-sans-serif;

  // It seems the Bootstrap folks wanted to leave this behavior up to the downstream devs:
  // https://github.com/twbs/bootstrap/pull/21812
  cursor: pointer;
}

.no-form-highlighting {
  box-shadow: none !important;
}

// Forms
// Form validation
// Z-index master list
// Navs

.nav-link {
  white-space: nowrap;
}

.nav.custom-vertical-nav {
  .nav-link {
    border-left: 5px solid transparent;

    &.disabled {
      color: $navbar-light-disabled-color;
    }
  }

  .show > .nav-link,
  .active > .nav-link,
  .nav-link.show,
  .nav-link.active {
    color: $navbar-light-active-color;
    font-weight: bold;
    border-left: 5px solid $primary;
  }
}

// Navbar
// Dropdowns
// Pagination
// Cards

.card {
  &.border-none {
    background-color: transparent;
    border-color: transparent;
  }

  .card-header {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-bottom: 0;
      margin-top: 0;
    }
  }
}

// Tooltips
// Popovers
// Toasts
// Badges

.badge {
  font-family: lato, sans-serif;
}

// Modals

.modal-has-title-bar {
  .modal-content {
    background: none;
  }

  .modal-header,
  .modal-body,
  .modal-footer {
    background-color: $white;
  }
}

.modal-title {
  width: 100%;
}

// Alerts
// Progress bars
// List group
// Image thumbnails
// Figures
// Breadcrumbs

.breadcrumb {
  font-family: lato, sans-serif;
  font-weight: 300;
}

// Carousel
// Spinners
// Close
// Code
// Utilities

.cursor-pointer {
  cursor: pointer;
}

.cursor-move {
  cursor: move;
}

.right-0 {
  right: 0;
  left: auto;
}

.top-0 {
  top: 0;
}

.mw-1200px {
  max-width: 1200px !important;
}

.minw-20 {
  min-width: 20% !important;
}

.minw-30 {
  min-width: 30% !important;
}

.minw-50 {
  min-width: 50% !important;
}

.minw-60 {
  min-width: 60% !important;
}

// Printing

th,
td {
  .sort-direction-indicator {
    display: none;
  }

  &:hover .sort-direction-indicator {
    display: inherit;
  }
}

.active {
  .sort-direction-indicator { /* stylelint-disable-line */
    display: inherit;
  }
}
