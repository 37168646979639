@import "billing_accounts/billing_table_container";
@import "billing_accounts/card_billing_plans_container";
@import "billing_accounts/new_billing_account_wrapper";

.billing_accounts { /* stylelint-disable-line */
  @import "billing_accounts/edit_plans"; /* stylelint-disable-line */

  .hero {
    .hero-text {
      padding: 1% 3%;

      h1 {
        font-size: 2.25rem;
        line-height: 3rem;
      }

      p {
        font-size: 1rem;
        line-height: 1.7rem;
      }
    }
  }

  #plans-toggle {
    .switch-wrapper {
      border-radius: 30px;
      width: 80%;
      border: 1px solid gainsboro;
      background: #fff;
      margin: auto;
      margin-bottom: 15px;
      padding: 0;
      float: none;
      text-align: center;
      display: block;

      span {
        float: left;
        font-size: 18px;
        padding: 5px;
        width: 50%;
        border-radius: 30px;

        &.selected {
          font-weight: bold;
        }

        &.annual {
          float: right;
        }
      }

      br {
        clear: both;
      }
    }

    .badge {
      float: right;
      margin-top: 5px;
      font-size: 13px;
      padding: 3px 4px;
      width: 46%;
      margin-right: 2%;
      color: $matcha-70;
      background: $matcha-20;
      border: 1px solid $matcha-50;
    }
  }

  // NB: this class does not exist in the codebase, it is the class of Stripe's Javascript injected
  // Credit Card form -- do not delete this, as it is important to the styling of the CC payment form!
  // It's found on the BillingAccountsController#edit_plan page.
  .StripeElement--focus { /* stylelint-disable-line */
    color: #495057;
    background-color: #fff;
    border-color: #ffe4de;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(255 124 94 / 25%);
  }

  // Match styling of Stripe input
  input.form-control.billing-input {
    font-family: Arial, sans-serif;

    &::placeholder {
      color: #aab7c4;
    }
  }

  .fine-print {
    text-align: center;
    display: block;
    font-style: italic;
    font-size: 13px;
  }

  // KT: Undo _client_firm.css.erb's client firm whitelabeling (by re-overriding with the defaults, namespaced within
  // the .billing_accounts class, just for this component, because the whitelabeling throws the colors off, and we want
  // these pages to look good specifically ...

  .text-primary {
    --bs-text-opacity: 1;

    color: rgba($primary, var(--bs-text-opacity)) !important;
  }

  .btn-primary {
    background-color: var(--bs-btn-bg);
    border-color: var(--bs-btn-bg);
  }

  .btn-primary:hover {
    background-color: var(--bs-btn-hover-bg);
    border-color: var(--bs-btn-hover-border-color);
  }

  .btn-primary:focus {
    background-color: var(--bs-btn-hover-bg);
    border-color: var(--bs-btn-hover-border-color);
    box-shadow: var(--bs-btn-focus-box-shadow);
  }

  .btn-primary:disabled,
  .btn-primary.disabled {
    border-color: var(--bs-btn-disabled-bg);
  }

  .btn-primary:active,
  .btn-primary.active {
    background-color: var(--bs-btn-active-bg);
    border-color: var(--bs-btn-active-border-color);
  }

  .btn-primary:active:focus,
  .btn-primary.active:focus {
    box-shadow: var(--bs-btn-focus-box-shadow);
  }
}
