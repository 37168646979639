// https://stackoverflow.com/a/45792490
.billing-table-container-rounded-top {
  position: relative;
  overflow: hidden;
  height: 50px;

  &::before {
    border-radius: 100%;
    position: absolute;
    background: $matcha-20;
    right: -75px;
    left: -75px;
    top: 0;
    bottom: -25px;
    content: "";
    z-index: -1;
  }
}

.billing-table-container {
  background-color: $matcha-20;
  text-align: center;

  @media (max-width: 575.98px) {
    padding: 0 1rem;
  }

  h2,
  h3,
  h4 {
    font-weight: bold !important;
  }

  h2 {
    font-size: 2.25rem;

    @media (max-width: 575.98px) {
      font-size: 1.3rem;
    }
  }

  h3 {
    font-size: 1.85rem;

    @media (max-width: 575.98px) {
      font-size: 1.4rem;
    }
  }

  h4 {
    font-size: 1.6rem;

    @media (max-width: 575.98px) {
      font-size: 1rem;
    }
  }

  .table > :not(:first-child) {
    border: none;
  }

  table {
    height: 100%; // https://stackoverflow.com/a/49797662
    text-align: center;
    width: 100%;
    margin-top: 0;

    @media (max-width: 575.98px) {
      margin-top: 1.5rem;
    }

    color: $gray-600 !important;

    tbody {
      border: none;
    }

    th {
      &.title {
        border-bottom: 1px solid gainsboro !important;
        text-align: left;
        width: 25%;
        font-size: 1.2rem;
        font-weight: bolder;

        @media (max-width: 575.98px) {
          font-size: 0.8rem;
        }

        padding-bottom: 0;
        padding-top: 20px;

        &.minor {
          font-size: 1.2rem;
          font-weight: normal;

          @media (max-width: 575.98px) {
            font-size: 0.8rem;
          }
        }
      }

      border: none;
    }

    tr {
      border: none;
      height: 100%; // https://stackoverflow.com/a/49797662

      td {
        padding: 0;
        border: none;
        height: 1px; // https://stackoverflow.com/a/49797662

        span {
          background-color: #fff;
          font-weight: bold;
          font-size: 1.5rem;

          @media (max-width: 575.98px) {
            font-size: 0.8rem;
            min-height: 75px;
          }

          display: block;
          width: 95%;
          margin: auto;
          float: none;
          height: 100%;
          border-bottom: 1px solid rgb(242 242 242);
          padding: 15px;

          &.text-success,
          &.text-primary {
            font-style: italic !important;
          }

          &.muted {
            font-size: 1.2rem;
            color: $gray-500 !important;
          }

          i {
            font-size: 1.8rem;

            @media (max-width: 575.98px) {
              font-size: 1.2rem;
              margin-top: 0.8rem;
            }

            vertical-align: center;

            &.pro {
              color: lighten($matcha-60, 15%);
            }

            &.team {
              color: lighten($primary, 15%);
            }
          }
        }
      }
    }

    .button-block {
      i {
        @media (max-width: 575.98px) {
          display: none;
        }
      }

      a {
        @media (max-width: 575.98px) {
          font-size: 0.8rem;
        }
      }
    }

    tr.upgrade td span {
      min-height: 73px;

      i {
        font-size: 1rem;
      }
    }

    tr.rounded {
      td {
        span {
          border-top-right-radius: 10px;
          border-top-left-radius: 10px;
        }
      }
    }
  }

  .table > :not(caption) > * > * {
    max-width: 50px;
    width: 50px;
    border: none;
  }
}
