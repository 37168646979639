// This works together with the _simple_format_and_truncate.html.erb partial.
// Add additional truncate-lines-x classes as needed ...

.truncate-lines {
  overflow: hidden;
  position: relative;

  &.truncate-lines-3 {
    height: 3.1em;
  }

  &.truncate-lines-7 {
    height: 7.1em;
  }

  &::after {
    content: "";
    text-align: right;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 1.2em;
    background: linear-gradient(to bottom, rgb(250 250 250 / 0%), rgb(250 250 250 / 100%) 100%);
  }
}
