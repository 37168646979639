// https://getbootstrap.com/docs/4.3/utilities/sizing/

.w-0 {
  width: 1px;
}

.h-0 {
  height: 1px;
}

.w-33 {
  width: 33%;
}

.w-md-15 {
  @include media-breakpoint-up(md) {
    width: 15%;
    max-width: 15%;
    min-width: 15%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.w-md-18 {
  @include media-breakpoint-up(md) {
    width: 18%;
    max-width: 18%;
    min-width: 18%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.h-33 {
  height: 33%;
}

.w-66 {
  width: 66%;
}

.h-66 {
  height: 66%;
}

.constrain-child-iframes {
  iframe {
    max-width: 100%;
  }
}
