html body {
  .admin-subtab {
    .nav-link {
      color: $primary;

      &.active {
        color: #fff;
        background: $primary;
      }
    }
  }
}
