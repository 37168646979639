// KT: Used in our messaging component.
// These are the little "quote bubbles" that point out
// at the user's profile pic.
// Adapted from AirBnB's messaging page.

.card-quote {
  &::before,
  &::after {
    width: 0;
    height: 0;
    content: " ";
    position: absolute;
    border-style: solid;
  }

  position: relative;

  // don't allow the message contents to escape
  div,
  input {
    overflow: hidden;
  }
}

.card-quote-flush {
  margin-left: 0;

  &.card-quote-end {
    margin-right: 0;
  }
}

.card-quote-end {
  border-top-right-radius: 0;
}

.card-quote-start {
  border-top-left-radius: 0;
}

.card-quote-start::before {
  top: -1px;
  left: initial;
  right: 100%;
  border-width: 0 27px 27px 0;
  border-color: transparent #dce0e0 transparent transparent;
}

.card-quote-start::after {
  top: 0;
  left: initial;
  right: 100%;
  border-width: 0 25px 25px 0;
  border-color: transparent $white transparent transparent;
}

// KT: Note the color of the border on the quote is actually a blend of the two colors in the before and after
// rules.  So, it's kinda mysterious.  You have to work at it.  This tool is useful:
// https://meyerweb.com/eric/tools/color-blend/

.card-quote-end::before {
  top: -1px;
  left: 100%;
  border-width: 27px 27px 0 0;
  border-color: #d1d5d5 transparent transparent;
}

.card-quote-end::after {
  top: 0;
  left: 100%;
  border-width: 25px 25px 0 0;
  border-color: #f8f9fa transparent transparent;
}

.card-quote-end-white::after {
  border-color: $white transparent transparent;
}
