// Allows divs within Bootstrap containers,
// to break out of the margins of those containers,
// in order to push background colors etc to the edges of the page.
// https://stackoverflow.com/a/37729410
.container-breakout-pagewidth {
  position: relative;
  width: calc(var(--vw, 1vw) * 100);

  // BUGFIX: https://github.com/FortAwesome/vue-fontawesome/issues/341#issuecomment-1074930424
  left: calc(-50 * var(--vw, 1vw));
  margin-left: 50%;
}
