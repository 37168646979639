.media-match-paywall {
  width: 60% !important;
  max-width: 600px !important;
  border-radius: 20px !important;

  .modal-header {
    background-image: linear-gradient(to right, #2575fc, #6a11cb);
    position: relative;
    overflow: hidden;
    height: 100px;

    button {
      position: absolute;
      right: 10px;
      top: 8px;
      color: #fff !important;
    }

    i.fa-close {
      font-size: 25px;
      position: absolute;
      top: 4px;
      right: 7px;
    }

    i.fa-bullhorn {
      float: right;
      opacity: 0.2;
      font-size: 105px;
      color: #fff;
      transform: rotate(-29deg);
      position: absolute;
      right: 100px;
      top: 5px;
    }
  }

  .modal-body {
    position: relative;

    i.fa-circle-user {
      position: absolute;
      left: 25px;
      top: -45px;
      font-size: 80px;
      border-radius: 300px;
      border: 2px solid #fff;
      background: #fff;
    }

    h4 {
      font-size: 24px;
      color: #000;
    }

    p {
      font-size: 18px;
      line-height: 28px;
    }

    .secondary-header {
      p.price {
        font-size: 28px;
        line-height: 36px;
      }
    }
  }
}
