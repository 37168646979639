.source_press_releases { /* stylelint-disable-line */
  .card {
    border: 1px solid darken(#f9f9f9, 5%) !important;
    border-radius: 10px;
  }

  td,
  th {
    padding: 1rem !important;
  }

  .table-striped > tbody > tr:nth-child(odd) > td,
  .table-striped > tbody > tr:nth-child(odd) > th {
    background-color: lighten(#f9f9f9, 10%) !important;
  }

  .status {
    text-align: center;
    font-size: 12px;

    &.warning {
      background: $gold-10;
      border: 1px solid $gold-60;
      border-radius: 60px;
      color: $gold-70;
      width: 35%;
      display: block;
    }

    &.success {
      background: $matcha-10;
      border: 1px solid $matcha-60;
      color: $matcha-70;
      border-radius: 60px;
      width: 35%;
      display: block;
    }
  }

  a.nav-link.border-0.active {
    background: #f3f4f6;
  }
}
