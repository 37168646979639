/* stylelint-disable no-descending-specificity */
&.edit_plan { /* stylelint-disable-line */
  .btn.btn-primary.btn-lg {
    width: auto;
    margin: auto;
    display: block;
    float: none;
  }

  .card {
    border: none;
    box-shadow: 1px 1px 5px gainsboro;
    padding: 25px 15px 15px;

    .card-header {
      background: transparent;
      border: none;
      margin-bottom: 5px;
    }

    .card-body {
      .card {
        box-shadow: none !important;
        margin-top: 0 !important;
        padding-top: 0 !important;
        min-height: auto !important;

        .card-body {
          ul {
            margin-top: 55px !important;
          }
        }
      }
    }
  }

  .plan-summary {
    i {
      margin-right: 4px;
      color: $gray-400;
      font-size: 14px;
    }

    .details {
      margin-top: 20px;
    }

    .meta {
      background: lighten($gray-100, 10%);
      background: #f2f2f2;
      border-radius: 7px;
      padding: 15px 7px;
      margin-bottom: 20px;

      i {
        color: $primary;
        font-size: 16px;
      }

      #billing-contact-section,
      #payment-method-section {
        font-size: 14px !important;
      }
    }

    .card {
      .card-header {
        padding-left: 12px;
      }

      .card-body {
        border-radius: 15px;
        margin: 0 15px;
        padding: 5px !important;
      }
    }

    .discount-notice {
      margin-left: 10px;
    }
  }
}
/* stylelint-enable no-descending-specificity */
