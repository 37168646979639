.press_releases { /* stylelint-disable-line */
  .content-body {
    figure {
      width: 50%;
      float: left;
    }

    ul,
    li {
      list-style-type: none;
    }
  }

  .show {
    figure {
      img {
        max-width: 90%;
      }
    }

    .img-fluid,
    .img-thumbnail {
      max-width: 40%;
    }

    p {
      margin-bottom: 1.85rem;
      line-height: 1.85rem;
      font-size: 1.035rem;
    }

    ul li {
      line-height: 1.85rem;
    }
  }

  div.alert {
    background-color: #fefbed !important;
    border-color: color-mix(in srgb, #fcf1c0 85%, black 15%) !important;
    color: color(display-p3 0.534 0.269 0.114) !important;
    font-size: 16px !important;
  }
}
