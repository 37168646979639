.list-group-borderless {
  .list-group-item {
    border: none;
  }
}

.list-group-sm {
  .list-group-item {
    padding: 0.25em 0.5em;
  }
}

.list-group-xs {
  .list-group-item {
    padding: 0.15em 0;
  }
}
